import React , { useState,useContext, useEffect,useRef }from 'react'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { Line } from '@ant-design/charts';
import html2canvas from 'html2canvas';
import { SearchOutlined} from '@ant-design/icons';
import * as echarts from 'echarts';
import { Select, Button,   Table, Tabs,  message, DatePicker, Spin, Checkbox } from 'antd'
import moment from 'moment';
import { Mode } from '../../../../../App';
import './device.less'
let todayTime=0;
const { Option } = Select;
const { RangePicker } = DatePicker;
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
function dateStart() {
    var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
    return now_date;
}
function DeviceHistory(props) {
    const { state, dispatch } = useContext(StateContext)
    const [showSelDevNodes,setShowSelDevNodes] = useState([]);//要查询设备的所有测点
    const [selDevNodes,setSelDevNodes] = useState([]);//要查询设备的所有测点
    const [showSelDevNodesValue,setShowSelDevNodesValue] = useState([]);//要查询设备的所有测点名字
    const [indeterminate, setIndeterminate] = useState(false); //要查询设备的所有测点是否全选
    const [checkAll, setCheckAll] = useState(true);//要查询设备的所有测点设置全选
    const [checkedList,setCheckedList] = useState([]);//要查询的测点的id
    const [selNode, setSelNode] = useState([]);//要查询的测点的node
    const [selNodeColums, setSelNodeColums] = useState([]);//多测点查询表格列显示内容
    const [isHumi, setIsHumi] = useState(false);
    const [data, setData] = useState([])
    const [dataChart, setdataChart] = useState([])
    const [startTime, setStartTime] = useState('')//历史数据开始时间
    const [endTime, setEndTime] = useState('')
    const [isChangeTime,setIsChangeTime] = useState(false);
    const [dataF, setDataF] = useState({ tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 })
    const [seriesTempData,setSeriesTempData] = useState([]);
    const [tempChart,setTempChart] = useState(undefined);
    const [dataWainF, setDataWainF] = useState({ tl: 0, th: 0,  hl: 0, hh: 0 })
    const [isWaiting, setIsWaiting] = useState(false);
    const [showUnitName,setShowUnitName] = useState('')
    const [localCompany,setLocalCompany] = useState('');//本地公司名称
    const [userName,setUserName] = useState('');
    const [minInterval,setMinInterval] = useState(0);
    const [showUnit,setShowUnit] = useState('℃');
    const [showBound,setShowBound] = useState(false);
    const [recordCount,setRecordCount] = useState('');//记录条数
    const [tabKey,setTabKey] = useState("1")
    const [tableCurrent,setTableCurrent] = useState(1)
    const [tablePageSize,setTablePageSize] = useState(10)
    const [isExportHumi,setIsExportHumi] = useState(true);
    const plotRef = useRef()
    const configtemp = {
        autoFit:true,
        data: dataChart,
        padding: 'auto',
        // padding:[40,8,60,80],
        xField: 'data_time',
        //height: 500,
        // yField: props.item.Type===209?'val':'temp',
        yField: 'temp',
        seriesField:'name',
        slider: {
            start: 0,
            end: 1,
            backgroundStyle:{
                fill: 'white',
                fillOpacity: 0,
                columnStyle: {
                    fill: 'white',
                    fillOpacity: 0,
                }
            },
            foregroundStyle:{
                fill: 'white',
                fillOpacity: 0,
            },
            trendCfg:{
                lineStyle:{stroke:'white'}
            }
        },
        connectNulls:false,
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                        return ''.concat(s, ',');
                    })+(props.item.Type===205?'Pa':props.item.Type===210?'MPa':'℃');
                },
            },
            max:dataWainF.th>dataF.tmax?dataWainF.th+2:dataF.tmax+2,
            min:dataWainF.tl<dataF.tmin?dataWainF.tl-2:dataF.tmin-2,
        },
        xAxis: {
            label: {
                offset:20
            },

        },
        annotations:showBound? [
            {
                type: 'line',
                start: ['start',dataWainF.th],
                end:['end',dataWainF.th],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.th],
                content: `${dataWainF.th+(props.item.Type===205?'Pa':props.item.Type===210?'MPa':'℃')}(上)`,
                offsetY: -4,
                offsetX:-18,
                style: {
                  textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['start',dataWainF.tl],
                end:['end',dataWainF.tl],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.tl],
                content: `${dataWainF.tl+(props.item.Type===205?'Pa':props.item.Type===210?'MPa':'℃')}(下)`,
                offsetY: 18,
                offsetX:-18,
                style: {
                  textBaseline: 'bottom',
                },
            },
        ]:[]
        ,
        legend: {
            position: 'top-left',
            items: [
                {
                    name: showUnitName,
                    marker: {
                      symbol: 'square',
                      style: {
                        fill: '#00bc12',
                      },
                    },
                },
            ],
        }, 
        color: ['#00bc12','#FFB6C1','#FFB6C1',],
    };
    const confighumi = {
        data: dataChart,
        autoFit:true,
        padding: 'auto',
        // padding:[16,8,16,200],
        xField: 'data_time',
        yField: 'humi',
        seriesField:'name',
        slider: {
            start: 0,
            end: 1,
            backgroundStyle:{
                fill: 'white',
                fillOpacity: 0,
                columnStyle: {
                    fill: 'white',
                    fillOpacity: 0,
                }
            },
            foregroundStyle:{
                fill: 'white',
                fillOpacity: 0,
            },
            trendCfg:{
                lineStyle:{stroke:'white'}
            }
        },
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    
                    return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                        return ''.concat(s, ',');
                    });
                },
            },
            max:(dataWainF.hh>97||dataF.hmax>97)?100:dataWainF.hh>dataF.hmax?dataWainF.hh+2:dataF.hmax+2,
            min:(dataWainF.hl<3||dataF.hmin<3)?0:dataWainF.hl<dataF.hmin?dataWainF.hl-2:dataF.hmin-2,
        },
        xAxis: {
            label: {
                offset:20
            },

        },
        annotations:showBound? [
            {
                type: 'line',
                start: ['start',dataWainF.hh],
                end:['end',dataWainF.hh],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.hh],
                content: dataWainF.hh+'(上)',
                offsetY: -4,
                offsetX: -18,
                style: {
                  textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['start',dataWainF.hl],
                end:['end',dataWainF.hl],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.hl],
                content: dataWainF.hl+'(下)',
                offsetY: 18,
                offsetX: -18,
                style: {
                  textBaseline: 'bottom',
                },
            },
        ]:[],
        legend: {
            position: 'top-left',
            items: [
                {
                    name: '湿度',
                    marker: {
                      symbol: 'square',
                      style: {
                        fill: '#1979C9',
                      },
                    },
                },
            ],
          }, //
          color: ['#1979C9','#FFB6C1','#FFB6C1'],
    };
    const configtemps = {
        data: dataChart.filter(x=>x.node!==0),
        padding: 'auto',
        xField: 'data_time',
        autoFit:true,
        yField: 'temp',
        seriesField: 'name',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    var i = v.indexOf('.');
                    return i===-1?v:v.slice(0,i+2);
                },
            },
            // tickCount:10
            max:dataWainF.th>dataF.tmax?dataWainF.th+2:dataF.tmax+2,
            min:dataWainF.tl<dataF.tmin?dataWainF.tl-2:dataF.tmin-2,
        },
        xAxis: {
            label: {
                offset:20
            },

        },
        slider: {
            start: 0,
            end:1,
            backgroundStyle:{
                fill: 'white',
                fillOpacity: 0,
                columnStyle: {
                    fill: 'white',
                    fillOpacity: 0,
                }
            },
            foregroundStyle:{
                fill: 'white',
                fillOpacity: 0,
            },
            trendCfg:{
                lineStyle:{stroke:'white'}
            }
        },
        annotations:showBound? [
            {
                type: 'line',
                start: ['start',dataWainF.th],
                end:['end',dataWainF.th],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.th],
                content: `${dataWainF.th}℃(上)`,
                offsetY: -4,
                offsetX:-18,
                style: {
                  textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['start',dataWainF.tl],
                end:['end',dataWainF.tl],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.tl],
                content: `${dataWainF.tl}℃(下)`,
                offsetY: 18,
                offsetX:-18,
                style: {
                  textBaseline: 'bottom',
                },
            },
        ]:[],
        // color: '#D62A0D',
        color: ['#00bc12','#D62A0D', '#9900FF', '#000000','#335541','#656485','#784512','#255454','#621532','#785423','#996655','#996699','#9999CC','#99CC99','#99FFFF'],
    };
    const confighumis = {
        data: dataChart.filter(x=>x.node!==0),
        padding: 'auto',
        xField: 'data_time',
        autoFit:true,
        yField: 'humi',
        seriesField: 'name',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    var i = v.indexOf('.');
                    return i===-1?v:v.slice(0,i+2);
                },
            },
            // tickCount:10
            max:(dataWainF.hh>97||dataF.hmax>97)?100:dataWainF.hh>dataF.hmax?dataWainF.hh+2:dataF.hmax+2,
            min:(dataWainF.hl<3||dataF.hmin<3)?0:dataWainF.hl<dataF.hmin?dataWainF.hl-2:dataF.hmin-2,
        },
        xAxis: {
            label: {
                offset:20
            },

        },
        slider: {
            start: 0,
            end:1,
            backgroundStyle:{
                fill: 'white',
                fillOpacity: 0,
                columnStyle: {
                    fill: 'white',
                    fillOpacity: 0,
                }
            },
            foregroundStyle:{
                fill: 'white',
                fillOpacity: 0,
            },
            trendCfg:{
                lineStyle:{stroke:'white'}
            }
        },
        annotations: showBound?[
            {
                type: 'line',
                start: ['start',dataWainF.hh],
                end:['end',dataWainF.hh],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.hh],
                content:dataWainF.hh+'(上)',
                offsetY: -4,
                offsetX: -18,
                style: {
                  textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['start',dataWainF.hl],
                end:['end',dataWainF.hl],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.hl],
                content:dataWainF.hl+'(下)',
                offsetY: 18,
                offsetX: -18,
                style: {
                  textBaseline: 'bottom',
                },
            },
        ]:[]
        ,
    };
    const option = {
        tooltip: {
            trigger: 'axis',
        },
        title: {
            left: 'center',
            text:isHumi?`${userName}\n${startTime}-${endTime}曲线图 \n ${showUnitName}最小值${dataF.tmin}${showUnitName}最大值${dataF.tmax}湿度最小值${dataF.hmin}湿度最大值${dataF.hmax}`:`${userName}\n${startTime}-${endTime}曲线图 \n ${showUnitName}最小值${dataF.tmin}${showUnitName}最大值${dataF.tmax}`
          },
        legend:{
            top:80,
            type:'scroll',
        },
        xAxis: {
            type: 'time',
            axisLabel:{
                formatter:`{HH}:{mm}:{ss}\n{yyyy}-{MM}-{dd} `
            },
            min:startTime,
            max:endTime
        },
        yAxis: {
            type: 'value',

            min: function (value) {
                return value.min.toFixed(1) - 2;
            },
            max : function (value) {
                return value.max.toFixed(1) +2;
            },      
            axisLabel:{
                formatter:function (value, index) {
                    return value.toFixed(1) ;
                }
            }
        },
        grid:
        {
            left:50,
            rigeht:0,
            bottom:0,
            top:120,
            containLabel:true
        },
        // dataZoom: [
        //   {
        //     type: 'inside',
        //     start: 0,
        //     end: 100
        //   },
        // ],
        series: seriesTempData,
    };
    const columns = [
        {
            title:'记录条数:'+recordCount,
            children:[
                {
                    title: '时间',
                    dataIndex: 'data_time',
                    align: 'center',
                    sorter: (a, b) =>new Date(a.data_time).getTime()  -new Date(b.data_time).getTime() ,
                }
            ]
            
        },
        {
            title:props.item.Node===0?null:showUnitName+`  (最大:${dataF.tmax.toFixed(1)} 
            最小:${dataF.tmin.toFixed(1)} 
            平均:${dataF.tavg.toFixed(2)})`,
            children:[
                {
                    title:props.item.Node===0?null:`上下限(${showUnit})`,
                    align: 'center',
                    className:props.item.Node===0&&'notshow',
                    render: (text, record) => (
                        <div>
                            {
                                props.item.Node===0?null
                                // :props.item.Type===209?<div>{`${record.sensorDatas.high}~${record.sensorDatas.low}`}</div>
                                : <div>{`${record['temp_h']}~${record['temp_l']}`}</div>
                            }
                        </div>
                    )
                },

                {
                    title: props.item.Node===0?null:`记录值(${showUnit})`,
                    align: 'center',
                    render: (text, record) => (
                        props.item.Node===0?null
                        // :props.item.Type===209?<span style={{color:record.sensorDatas.alarm === 1 ?'red':''}}>{record.sensorDatas.enable!==1?'--':record.sensorDatas.value>102.1?'NL':record.sensorDatas.value}</span>
                        :<span style={{color:record.temp_alarm === 1 ?'red':''}}>{record.temp_switch===0?'--':record.temp>102.1?'NL':record.temp.toFixed(1)}</span>
                    ),
                    className:props.item.Node===0&&'notshow'
                },
                
                {
                    title:  props.item.Node===0?null:'状态',
                    align: 'center',
                    render: (text, record) => (
                        props.item.Node===0?null
                        // :props.item.Type===209?
                        // <div style={{color:record.sensorDatas.alarm === 1 ?'red':''}}>{record.sensorDatas.enable===0?'停用':record.sensorDatas.alarm === 1 ? '报警' : '正常'}</div>
                        :<div style={{color:record.temp_alarm === 1 ?'red':''}}>{record.temp_switch===0?'停用':record.temp_alarm === 1 ? '报警' : '正常'}</div>
                    ),
                    className:props.item.Node===0&&'notshow'
                },
            ],
            className:props.item.Node===0&&'notshow'
            
        },
        {
            title:(props.item.Node!==0&&props.item.Humi===1)?'湿度'+`  (最大:${dataF.hmax.toFixed(1)} 
            最小:${dataF.hmin.toFixed(1)} 
            平均:${dataF.havg.toFixed(2)})`:null,
            children:[
                {
                    title: (props.item.Node!==0&&props.item.Humi===1)?'上下限(%RH)':null,
                    align: 'center',
                    render: (text, record) => 
                    (props.item.Node!==0&&props.item.Humi===1)?
                    (
                        <div>{record.humi_h+'~'+record.humi_l}</div>
                    )
                    :null,
                    className:(props.item.Node===0||props.item.Humi!==1)&&'notshow'
                },
                {
                    title: (props.item.Node!==0&&props.item.Humi===1)?'记录值(%RH)':null,
                    align: 'center',
                    render: (text, record) => 
                    (props.item.Node!==0&&props.item.Humi===1)?
                    (
                        <div style={{color:record.humi_alarm === 1 ?'red':''}}>{record.humi_switch===0?'--':record.humi>102.1?'NL':record.humi.toFixed(1)}</div>                           
                    )
                    :null,
                    className:(props.item.Node===0||props.item.Humi!==1)&&'notshow'
                },
                
                {
                    title: props.item.Node===0?null:props.item.Humi===1?'状态':null,
                    align: 'center',
                    render: (text, record) => 
                    (props.item.Node!==0&&props.item.Humi===1)?
                    (
                        <div style={{color:record.humi_alarm === 1 ?'red':''}}>{record.humi_switch===0?'停用':record.humi_alarm === 1 ? '报警' :record['humi_alarm'] === 0 ? '正常':null}</div>
                    ):null,
                    className:(props.item.Node===0||props.item.Humi!==1)&&'notshow'
                },
            ],
            className:(props.item.Node===0||props.item.Humi!==1)&&'notshow'

        },
        {
            title: (props.item.Node===0&&props.item.Sensor.DoorAlarm===1)?'开关门':null,
            dataIndex: 'door',
            align: 'center',
            render: (text, record) => 
            (props.item.Node===0&&props.item.Sensor.DoorAlarm===1)?
            (
                <div>
                    <div>{record.door===0?'关门':'开门'}</div>
                </div>
            )
            :null,
            className:(props.item.Node!==0||props.item.Sensor.DoorAlarm!==1)&&'notshow'
        },
        
        {
            title: '信号',
            dataIndex: 'csq',
            align: 'center',
        },
        {
            title: '电源',
            dataIndex: 'power',
            align: 'center',
            render:(text,record)=>(
                <div>{record.power === 0?"正常":"电池"}</div>
            )
        },
        {
            title: '电量',
            dataIndex: 'battery',
            align: 'center',
        },
    ]
    const columnsLeft = [
        {
            title:'记录条数:'+recordCount,
            children:[
                {
                    title: '时间',
                    dataIndex: 'data_time',
                    key: 'id',
                    align: 'center  ',
                    width: 160,
                    fixed: true,
                    sorter: (a, b) =>new Date(a.data_time).getTime()  -new Date(b.data_time).getTime() ,
                }
            ]

        },
    ]
    const columnsRight = [

        {
            title: '信号',
            dataIndex: 'csq',
            key: 'id',
            align: 'center',
            width: 80,
        },
        {
            title: '电源',
            dataIndex: 'power',
            key: 'id',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <div>{record.power === 0 ? "正常" : "电池"}</div>
            )
        },
        {
            title: '电量',
            dataIndex: 'battery',
            key: 'id',
            align: 'center',
            width: 80,
        },
    ]
     // 单测点查询历史数据
     const getHistory = (starttime, endtime,unitName) => {
        setIsWaiting(true)
        setRecordCount('');
        setShowBound(true)
        setDataF({tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });
        setDataWainF({th:0,tl:0,hh:0,hl:0});
        setdataChart([]);
        setData([]);
        setTimeout(() => {

            http.get("/v1/history?device=" + props.item.Sn + "&node=" + props.item.Node + "&starttime=" + starttime + "&endtime=" + endtime+"&min="+minInterval, {})
                .then(data => {
                    setIsWaiting(false)
                    setIsHumi(false);
                    let is_Humi = false;
                    if (data.code === 0) {
                        if (data.data===null||data.data.length === 0) return message.warning('暂无数据');
                        var artemp= data.data.filter(item => item.temp !== 102.3 && item.temp !== 102.2 &&item.temp_switch!==0);
                        let ar = JSON.parse(JSON.stringify(artemp));
                        var arLen = ar.length;
                        setData(data.data.reverse());
                        setRecordCount(data.data.length+'');
                        if(props.item.Node===0) return
                        if (arLen === 0 ) return message.warning('暂无有效数据');
                        let checkData = ar[0];
                        if(checkData.node===0){
                            checkData= ar[1];
                        }
                        if(checkData.humi_switch!==0&&checkData.humi_enable!==0){
                            setIsHumi(true);
                            is_Humi = true;
                        }
                        var tavg = 0
                        var tmax = -100
                        var tmin = 100
                        var havg = 0
                        var hmax = 0
                        var hmin = 100
                        ar.map(item => {
                            if (item.temp > tmax) {
                                tmax = item.temp
                            }
                            if (item.temp < tmin) {
                                tmin = item.temp
                            }
                            tavg = tavg + item.temp
                            if (item.humi > hmax) {
                                hmax = item.humi
                            }
                            if (item.humi < hmin) {
                                hmin = item.humi
                            }
                            havg = havg + item.humi
                        })
                        tavg = tavg / arLen
                        havg = havg / arLen
                        setDataF({ tmax, tmin, tavg, hmin, hmax, havg });
                        let tH = props.item.Sensor.TempAlarmHigh;
                        let tL = props.item.Sensor.TempAlarmLow;
                        let hH = props.item.Sensor.HumiAlarmHigh;
                        let hL = props.item.Sensor.HumiAlarmLow;
                        if(props.item.Sensor.Hide===2||props.item.Sensor.Hide===3){
                            setShowBound(false)
                            setDataWainF({th:dataF.tmax,tl:dataF.tmin,hh:dataF.hmax,hl:dataF.hmin});
                        }else{
                            setDataWainF({th:tH,tl:tL,hh:hH,hl:hL});
                        }
                        let maxNum = ar.length;
                        let normalData = [];
                        let warnData=[];
                        let start ;let end;
                        let seriesData=[]
                        let tempdata =[];
                        let humidata =[];
                        let name ='';
                        ar.map(val=>{
                            tempdata.push([val.data_time,val.temp]);
                            humidata.push([val.data_time,val.humi]);
                            if(name==='' ){
                                name =val.name
                            }
                        })
                        ar[0].data_time!==startTime&&tempdata.unshift(null);
                        ar[ar.length-1].data_time!==endTime&&tempdata.push(null);
                        seriesData.push({
                            name:name+(unitName?unitName:showUnitName)+'曲线',
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            data: tempdata,
                        })
                        is_Humi&&seriesData.push({
                            name:name+'湿度曲线',
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            data: humidata,
                        })
                        setSeriesTempData(seriesData);
                        if(maxNum<20000){
                            warnData=ar;
                        }
                        if(maxNum>20000&&maxNum<=50000){
                            ar.map(m=>{
                                if(m.temp_alarm===1||m.humi_alarm===1){
                                    warnData.push(m)
                                }else{
                                    normalData.push(m)
                                }
                            })
                            normalData.map((m,i)=>{
                                i%2==0&&warnData.push(m);
                            })
                            warnData.sort((a,b)=>a.data_time>b.data_time);
                        }
                        if(maxNum>50000&&maxNum<=100000){
                            ar.map(m=>{
                                if(m.temp_alarm===1||m.humi_alarm===1){
                                    warnData.push(m)
                                }else{
                                    normalData.push(m)
                                }
                            })
                            normalData.map((m,i)=>{
                                i%3==0&&warnData.push(m);
                            })
                            warnData.sort((a,b)=>a.data_time>b.data_time);
                            
                        }
                        if(maxNum>100000&&maxNum<=200000){
                            ar.map(m=>{
                                if(m.temp_alarm===1||m.humi_alarm===1){
                                    warnData.push(m)
                                }else{
                                    normalData.push(m)
                                }
                            })
                            normalData.map((m,i)=>{
                                i%6==0&&warnData.push(m);
                            })
                            warnData.sort((a,b)=>a.data_time>b.data_time);
                        }
                        if(maxNum>200000){
                            ar.map(m=>{
                                if(m.temp_alarm===1||m.humi_alarm===1){
                                    warnData.push(m)
                                }else{
                                    normalData.push(m)
                                }
                            })
                            normalData.map((m,i)=>{
                                i%10==0&&warnData.push(m);
                            })
                            warnData.sort((a,b)=>a.data_time>b.data_time);
                        }
                        start =  warnData[0];
                        end = warnData[warnData.length-1];
                        start.data_time!==starttime&&warnData.unshift({data_time:starttime,temp:null});
                        end.data_time!==endtime&&warnData.push({data_time:endtime,temp:null});
                        setdataChart(warnData);
                    }
                // })
            })
        }, 500);

    };
     // 单测点查询历史数据json
     const getHistoryJson = (starttime, endtime,unitName) => {
        setShowBound(true)
        setDataF({tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });
        setDataWainF({th:0,tl:0,hh:0,hl:0});
        setdataChart([]);
        setData([]);
        setTimeout(() => {
            http.get("/v1/history?device=" + props.item.Sn + "&node=" + props.item.Node + "&starttime=" + starttime + "&endtime=" + endtime+"&min="+minInterval, {})
            .then(data => {
                setIsWaiting(false)
                setIsHumi(false);
                if (data.code === 0) {
                    if (data.data===null||data.data.length === 0) return message.warning('暂无数据'); 
                    let tempData =[];
                    data.data.map(item=>{
                        if(item.json_data.Valid){
                            item.sensorDatas=JSON.parse(item.json_data.String)[0];
                            item.val = item.sensorDatas.value;
                        }else{
                            item.sensorDatas=null;
                        }
                        tempData.push(item);
                    })
                    let filteData = tempData.filter(item => item.val !== 102.3 && item.val !== 102.2 &&item.sensorDatas.enable!==0);
                    // setData(JSON.parse(JSON.stringify(tempData)).reverse())
                    setData(tempData.reverse())
                    var tavg = 0
                    var tmax = -100
                    var tmin = 100
                    var havg = 0
                    var hmax = 0
                    var hmin = 100
                    filteData.map(item => {
                        let value = item.sensorDatas.value;
                        if (value > tmax) {
                            tmax = value
                        }
                        if (value < tmin) {
                            tmin = value
                        }
                        tavg = tavg + value
                    })
                    tavg = tavg / filteData.length
                    setDataF({ tmax, tmin, tavg, hmin, hmax, havg });
                    let tH = filteData[0].sensorDatas.high;
                    let tL = filteData[0].sensorDatas.low;
                    setDataWainF({th:tH,tl:tL});
                    let start ;let end;
                    let seriesData=[]
                    let timedata =[];
                    let antvData =[];
                    let name ='';
                    //时间轴曲线
                    filteData.map(val=>{
                        timedata.push([val.data_time,val.sensorDatas.value]);
                        antvData.push(val);
                        if(name==='' ){
                            name =val.name
                        }
                    })
                    filteData[0].data_time!==startTime&&timedata.unshift(null);
                    filteData[filteData.length-1].data_time!==endTime&&timedata.push(null);
                    seriesData.push({
                        name:name+(unitName?unitName:showUnitName)+'曲线',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        data: timedata,
                    })
                    setSeriesTempData(seriesData);
                    //antv曲线
                    start =  antvData[0];
                    end = antvData[antvData.length-1];
                    start.data_time!==starttime&&antvData.unshift({data_time:starttime,val:null});
                    end.data_time!==endtime&&antvData.push({data_time:endtime,val:null});
                    setdataChart(antvData);
                }
            })
        }, 500);

    };
    // 多测点查询历史数据
    const getHistorys = (nodes,starttime, endtime,unitName,selDevNodes)=>{
        setDataF({tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });
        setdataChart([]);
        setData([]);
        setRecordCount('');
        // setTimeout(() => {
            if (nodes.length===0) return message.warning('未选择设备');
            let url = "/v1";   
            url += "/history?device=" + props.item.Sn + "&node=" + nodes + "&starttime=" ;
            url+=starttime+"&endtime=" + endtime;
            url+="&min="+minInterval;
            setIsWaiting(true);
            setIsHumi(false);
            http.get(url,{}).then((data) => {
                setIsWaiting(false);
                setDataWainF({th:0,tl:0,hh:0,hl:0});
                if (data.code === 0) {
                    if (data.data===null||data.data.length === 0) return message.warning('暂无数据');
                    let is_Humi=false;
                    let sss= data.data.find(x=>x.humi_switch!==0&&x.humi_enable!==0);//查找是否有湿度数据
                    if(sss){
                        setIsHumi(true);
                        is_Humi= true;
                    }
                    let baseData=[];
                    data.data.map(m=>{
                        let index = baseData.findIndex(x=>x.data_time===m.data_time);
                        if(index!==-1){
                            baseData[index]['temp' + m.node] = m.temp
                            baseData[index]['temp_h' + m.node] = m.temp_h
                            baseData[index]['temp_l' + m.node] = m.temp_l
                            baseData[index]['temp_alarm' + m.node] = m.temp_alarm
                            baseData[index]['temp_switch' + m.node] = m.temp_switch
                            baseData[index]['humi' + m.node] = m.humi
                            baseData[index]['humi_h' + m.node] = m.humi_h
                            baseData[index]['humi_l' + m.node] = m.humi_l
                            baseData[index]['humi_alarm' + m.node] = m.humi_alarm
                            baseData[index]['humi_switch' + m.node] = m.humi_switch
                            baseData[index]['humi_enable' + m.node] = m.humi_enable
                            baseData[index]['districtname' + m.node] = m.districtname
                            baseData[index]['door' + m.node] = m.door
                            baseData[index]['power' + m.node] = m.power
                        }else{
                            m['temp' + m.node] = m.temp
                            m['temp_h' + m.node] = m.temp_h
                            m['temp_l' + m.node] = m.temp_l
                            m['temp_alarm' + m.node] = m.temp_alarm
                            m['temp_switch' + m.node] = m.temp_switch
                            m['humi' + m.node] = m.humi
                            m['humi_h' + m.node] = m.humi_h
                            m['humi_l' + m.node] = m.humi_l
                            m['humi_alarm' + m.node] = m.humi_alarm
                            m['humi_switch' + m.node] = m.humi_switch
                            m['humi_enable' + m.node] = m.humi_enable
                            m['districtname' + m.node] = m.districtname
                            m['door' + m.node] = m.door
                            m['power' + m.node] = m.power
                            baseData.push(m)
                        }
                    })
                    setData(baseData.reverse());
                    setRecordCount(baseData.length+'');
                    var ar = data.data.filter(item => item.temp !== 102.3 && item.temp !== 102.2 &&item.temp_switch!==0);
                    if (ar.length === 0) return message.warning('暂无有效数据');
                    ar.sort((a,b)=>a.node-b.node);
                    let dev_val=[];
                    if(nodes.length>0){
                        var tavg = 0;
                        var havg = 0;
                        let isShowBound = true
                        let tmax = Math.max.apply(Math, ar.map(o=>o.temp));
                        let tmin = Math.min.apply(Math, ar.map(o=>o.temp));
                        let hmax = Math.max.apply(Math, ar.map(o=>o.humi)); 
                        let hmin = Math.min.apply(Math, ar.map(o=>o.humi)); 
                        
                        ar.map(item => {
                            if(isShowBound){
                                if(item.temp_h!==ar[0].temp_h||item.temp_l!==ar[0].temp_l||item.humi_h!==ar[0].humi_h||item.humi_l!==ar[0].humi_l){
                                    isShowBound = false
                                }
                            }
                            if(dev_val.length===0){
                                dev_val.push({node:item.node,temp_h:item.temp,temp_l:item.temp,humi_l:item.humi,humi_h:item.humi,temp_s:item.temp,humi_s:item.humi,count:1})
                            }else{
                                let b= dev_val.findIndex(x=>x.node===item.node);
                                if(b===-1){
                                    dev_val.push({node:item.node,temp_h:item.temp,temp_l:item.temp,humi_l:item.humi,humi_h:item.humi,temp_s:item.temp,humi_s:item.humi,count:1})
                                }else{
                                    dev_val[b].temp_s +=item.temp; 
                                    dev_val[b].humi_s +=item.humi; 
                                    dev_val[b].count +=1; 
                                    if(item.temp>dev_val[b].temp_h){
                                        dev_val[b].temp_h = item.temp
                                    }
                                    if(item.temp<dev_val[b].temp_l){
                                        dev_val[b].temp_l = item.temp
                                    }
                                    if(item.humi>dev_val[b].humi_h){
                                        dev_val[b].humi_h = item.humi
                                    }
                                    if(item.humi<dev_val[b].humi_l){
                                        dev_val[b].humi_l = item.humi
                                    }
                                }
                            }
                            tavg = tavg + item.temp;
                            havg = havg + item.humi;
                        })
                        tavg = tavg / ar.length;
                        havg = havg / ar.length;
                        setDataF({ tmax, tmin, tavg, hmin, hmax, havg });
                        setShowBound(isShowBound)
                        dev_val.map(item=>{
                            item.temp_s = item.temp_s/item.count
                            item.humi_s = item.humi_s/item.count
                        })
                    }
                    if(selNode.length===1){
                        if(props.item.Sensor.Hide===2||props.item.Sensor.Hide===3){
                            setShowBound(false)
                            setDataWainF({th:dataF.tmax,tl:dataF.tmin,hh:dataF.hmax,hl:dataF.hmin});
                        }else{
                            setDataWainF({th:ar[0].temp_h,tl:ar[0].temp_l,hh:ar[0].humi_h,hl:ar[0].humi_l});
                        }
                    }else{
                        setDataWainF({th:dataF.tmax,tl:dataF.tmin,hh:dataF.hmax,hl:dataF.hmin});
                    }
                    let start =  data.data[0];
                    let end = data.data[data.data.length-1];
                    start.data_time!==starttime&&ar.unshift({data_time:starttime,temp:null});
                    end.data_time!==endtime&&ar.push({data_time:endtime,temp:null});
                    setdataChart(ar);
                    let seriesData=[]
                    nodes.map(item=>{
                        let tempdata =[];
                        let humidata =[];
                        let name ='';
                        ar.filter(x=>x.node ==item).map(val=>{
                            tempdata.push([val.data_time,val.temp]);
                            humidata.push([val.data_time,val.humi]);
                            if(name==='' ){
                                name =val.name
                            }
                        })
                        start.data_time!==starttime&&tempdata.unshift(null);
                        start.data_time!==starttime&&humidata.unshift(null);
                        end.data_time!==endtime&&tempdata.push(null);
                        end.data_time!==endtime&&humidata.push(null);
                        if(name!==''){
                            seriesData.push({
                                'name':name+(unitName?unitName:showUnitName)+'曲线',
                                type: 'line',
                                smooth: true,
                                symbol: 'none',
                                data: tempdata,
                            })
                            is_Humi&&seriesData.push({
                                'name':name+'湿度曲线',
                                type: 'line',
                                smooth: true,
                                symbol: 'none',
                                data: humidata,
                            })
                        }
                    })
                    setSeriesTempData(seriesData);
                    let sd = selDevNodes
                    var nodeCol = new Array();
                    if(sd.length>1){
                        sd.sort((a,b)=>a.node-b.node);
                        if(sd[0].node===0){
                            let temp = sd.splice(0,1);
                            sd.push(temp[0]);
                        }
                    }
                    sd.map(({ node, name, district_name, m_name,id,door_alarm ,type,humi}) => {
                        let devNode= dev_val.filter(x=>x.node===node);
                        // nodes.push(node);
                        var t = 'temp' + node;
                        // 断电 开关门
                        if(node===0){
                            door_alarm===1&&
                            nodeCol.push({
                                title: `${name}-(区域:${district_name}-主机:${m_name}-测点ID:${node})`,
                                align: 'center',
                                children: [
                                    {
                                        title: '开关门',
                                        dataIndex: 'door' + node,
                                        width: 300,
                                        align: 'center',
                                        render: (text, record) => (
                                            <div>
                                                <div>{record['door' + node]  === 0 ? "关门" : '开门'}</div>
                                            </div>
                                        )
                                    },
                                    
                                ]
                            })
                        }else{//温湿度
                            humi===1?
                            // 温湿度
                            nodeCol.push({
                                title: `${name}-(区域:${district_name}-主机:${m_name}-测点ID:${node})`,
                                align: 'center',
                                children: [
                                    {
                                        title: `温度 (最大:${devNode.length>0&&devNode[0].temp_h.toFixed(1)} 
                                            最小:${devNode.length>0&&devNode[0].temp_l.toFixed(1)} 
                                            平均:${devNode.length>0&&devNode[0].temp_s.toFixed(2)})`,
                                        align: 'center',
                                        children:[
                                            {
                                                title: '上下限(℃)',
                                                // dataIndex: 'temp_h' + node,
                                                align: 'center',
                                                width:100,
                                                render: (text, record) => (
                                                    <div>
                                                        {record['temp_switch' + node] === 0 ? null :record['temp_h' + node]===undefined?'': `${record['temp_h' + node]}~${record['temp_l' + node]}`}
                                                    </div>
                                                )
                                            },
                                            {
                                                title: '温度(℃)',
                                                dataIndex: 'temp' + node,
                                                align: 'center',
                                                width:100,
                                                render: (text, record) => (
                                                    <div>
                                                        <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{ record['temp_switch' + node] === 0 ? "--" : record['temp' + node]?record['temp' + node] > 102.1 ? " NL" :record['temp' + node].toFixed(1):''}</div>
                                                    </div>
                                                )
                                            },
                                            {
                                                title: '状态',
                                                dataIndex: 'temp_alarm' + node,
                                                align: 'center',
                                                width: 60,
                                                render: (text, record) => (
                                                    <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{record['temp_switch' + node] === 0 ? "--" :record['temp_alarm' + node] === 0 ? '正常' :record['temp_alarm' + node] === 1 ? '报警':null}</div>
                                                )
                                            },
                                        ]
                                    },
                                    {
                                        title: `湿度 (最大:${devNode.length>0&&devNode[0].humi_h.toFixed(1)} 
                                            最小:${devNode.length>0&&devNode[0].humi_l.toFixed(1)} 
                                            平均:${devNode.length>0&&devNode[0].humi_s.toFixed(2)})`,
                                        align: 'center',
                                        children:[
                                            {
                                                title: '上下限(%RH)',
                                                // dataIndex: 'humi_h' + node,
                                                align: 'center',
                                                width: 100,
                                                render: (text, record) => (
                                                    <div>
                                                        {record['humi_switch' + node] === 0 || record['humi_enable' + node]===0 ? null :record['humi_h' + node]? <div>{`${record['humi_h' + node]}~${record['humi_l' + node]}`}</div>:''}
                                                    </div>
                                                )
                                            },
                                            {
                                                title: '湿度(%RH)',
                                                dataIndex: 'humi' + node,
                                                align: 'center',
                                                width: 100,
                                                render: (text, record) => (
                                                    <div>
                                                        <div style={{color:record['humi_alarm' + node] === 1 ?'red':''}}>{ record['humi_switch' + node] === 0 ? "--" : record['humi_enable' + node]===0 ?"--":record['humi' + node]?record['humi' + node] > 102.1 ? " NL" : record['humi' + node].toFixed(1):''}</div>
                                                    </div>
                                                )
                                            },
                                            {
                                                title: '状态',
                                                dataIndex: 'humi_alarm' + node,
                                                width: 60,
                                                align: 'center',
                                                render: (text, record) => (
                                                    <div style={{color:record['humi_alarm' + node] === 1 ?'red':''}}>{record['humi_switch' + node] === 0 ? "--" : record['humi_enable' + node]===0 ?"--" :record['humi_alarm' + node] === 0 ? '正常' : record['humi_alarm' + node] === 1 ? '报警':''}</div>
                                                )
                                            },
                                        ]
                                    },
                                    
                                ]
                            }):
                            // 单温设备
                            nodeCol.push({
                                title: `${name}-(区域:${district_name}-主机:${m_name}-测点ID:${node})`,
                                align: 'center',
                                children:[
                                {
                                        title:`${(type===205||type===210)?'压强':'温度'}  (最大:${devNode.length>0&&devNode[0].temp_h.toFixed(1)} 
                                        最小:${devNode.length>0&&devNode[0].temp_l.toFixed(1)} 
                                        平均:${devNode.length>0&&devNode[0].temp_s.toFixed(2)})`,
                                        align:'center',
                                        children: [
                                            {
                                                title: type===205?'上下限(Pa)':type===210?'上下限(MPa)':'上下限(℃)',
                                                // dataIndex: 'temp_h' + node,
                                                align: 'center',
                                                width:100,
                                                render: (text, record) => (
                                                    <div>
                                                        {record['temp_switch' + node] === 0 ? null :record['temp_h' + node]===undefined?'': <div>{`${record['temp_h' + node]}~${record['temp_l' + node]}`}</div>}
                                                    </div>
                                                )
                                            },
                                            {
                                                title: type===205?'压强(Pa)':type===205?'压强(MPa)':'温度(℃)',
                                                dataIndex: 'temp' + node,
                                                key: 'temp' + id,
                                                align: 'center',
                                                width:80,
                                                render: (text, record) => (
                                                    <div>
                                                        <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{ record['temp_switch' + node] === 0 ? "--" : record['temp' + node]?record['temp' + node] > 102.1 ? " NL" :record['temp' + node].toFixed(1):''}</div>
                                                    </div>
                                                )
                                            },
                                            {
                                                title: '状态',
                                                dataIndex: 'temp_alarm' + node,
                                                key: 'temp_alarm' + id,
                                                align: 'center',
                                                width: 60,
                                                render: (text, record) => (
                                                    <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{record['temp_switch' + node] === 0 ? "--" :record['temp_alarm' + node] === 0 ? '正常' :record['temp_alarm' + node] === 1? '报警':''}</div>
                                                )
                                            },
                                        ]
                                }
                                ]
                            })
                        }
                        
                    })
                    setSelNodeColums(nodeCol);
                }
            })
        // }, 200);
        
    }
    const onChange_Tab = key => {
        setTabKey(key)
        if (key==='2'){
            setTimeout(() => {
                let chartDom = document.getElementById('tempEcharts');
                if(chartDom){
                    let myChart = echarts.init(chartDom);
                    option && myChart.setOption(option);
                    myChart.resize();
                    myChart&&setTempChart(myChart);
                    myChart&&myChart.dispatchAction({
                        type: 'legendScroll',
                        scrollDataIndex: 5,
                        // legendId: string
                    })
                }    
            }, 500);
            
        }else{
            tempChart&&tempChart.dispose();
        }
    };
    const onChange_Table = (page,pageSize) =>{
        setTableCurrent(page)
        setTablePageSize(pageSize)
    }
    // 要历史查询设备的所有测点设置全选
    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ?  showSelDevNodesValue: []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        setSelDevNodes(e.target.checked ?  showSelDevNodes: [])
        let nodes = [];
        showSelDevNodes.map(({ node}) => {
            nodes.push(node);
        })
        setSelNode(nodes);
    };
    const onCheckChange = list=>{
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < showSelDevNodes.length);
        setCheckAll(list.length === showSelDevNodes.length);
        let nodes = [];
        let sd = showSelDevNodes.filter(x=>list.indexOf(x.value)>-1);
        setSelDevNodes(sd)
        sd.map(({ node}) => {
            nodes.push(node);
        })
        setSelNode(nodes);
    }
    // 历史数据 保存为图片
    const saveImage = () => {
        setIsWaiting(true);
        var canvas2 = document.createElement("canvas");
        let _canvas = document.getElementById('devicechart');
        var w = parseInt(window.getComputedStyle(_canvas).width);
        var h = parseInt(window.getComputedStyle(_canvas).height);
        canvas2.width = w ;
        canvas2.height = h ;
        // canvas2.style.width = (w +40) + "px";
        // canvas2.style.height = (h+40) + "px";
        var context = canvas2.getContext("2d");
        context.scale(1, 1);
        html2canvas(document.getElementById('devicechart'), { canvas: canvas2 }).then((canvas) => {
            // canvas.style.width = (w + 40) + "px";
            // canvas.style.height = (h + 40) + "px";
            canvas.id = 'cutimg'
            var url = canvas.toDataURL('image/png');
            if(window.CefSharp!==undefined){
                window.CefSharp.BindObjectAsync("bound");
                console.log(window.bound);
                window.bound.exportImg(url);
     
            }else{
                var downloadLink = document.createElement("a");
                downloadLink.href = url;
                downloadLink.download = "sd.png";
                downloadLink.click();
                
            }
            setIsWaiting(false);

        });
    }

    const antdPointClick =(plot) => {
        plot.on('element:click', args => {
         const {x,y} = args
         let points = args.data.points;
         // let data = args.data.data;
         points = points.reverse()
         let ele = points.reduce((prec,cur)=>{
             let xLen = Math.abs(prec.x-x);
             let xLen1 = Math.abs(cur.x-x);
             let yLen = Math.abs(prec.y-y);
             let yLen1 = Math.abs(cur.y-y);
             if(Math.sqrt(xLen*xLen+yLen*yLen)<Math.sqrt(xLen1*xLen1+yLen1*yLen1)){
                 return prec
             }else{
                 return cur
             }
         });
         let index = points.findIndex(val=>val.x===ele.x&&val.y===ele.y)
         setTabKey('1');
         setTableCurrent(Math.ceil(index/tablePageSize))
       });
     }
    useEffect(() => {
        Mode==='soc' && setUserName(state.district.filter(item => item.id === props.item.District)[0].user_name);
        Mode==='local'&&
        http.get("/v1/getconfig", {}).then(data => {
            if (data.code === 0) {
                setLocalCompany(data.data.Title)
            }
        })
        let unit = '℃';
        unit = props.item.Type===205?'Pa':props.item.Type===210?'MPa':'℃';
        let unitName = '温度';
        unitName=(props.item.Type===205||props.item.Type===210)?'压强':'温度';
        setShowUnit(unit);
        setShowUnitName(unitName);
        setStartTime(props.start);
        setEndTime(props.end)
        if(props.showMore){
            let checkOptions = 
            state.main.type===101?  state.device.filter(x=>x.node!==0&&x.sn==props.item.Sn&&x.district==props.item.District)
            : 
            state.device.filter(x=>x.node!==0&&x.sn==props.item.Sn&&x.district==props.item.District&&new Date(x.expire).getTime()>todayTime);
            let values =[];
            var nodes = new Array();
            checkOptions.map(m=>{
                m.value=m.id;
                m.label=m.name;
                values.push(m.id)
                nodes.push(m.node);
            })
            setShowSelDevNodes(checkOptions);
            setSelDevNodes(checkOptions)
            setSelNode(nodes);
            setShowSelDevNodesValue(values);
            setCheckedList(values);
            setTimeout(() => {
                getHistorys(nodes,props.start,props.end,unitName,checkOptions)
            }, 100);
        }else{
            getHistory(props.start,props.end,unitName);
        }
    }, [])
    useEffect(()=>{
        tempChart&& option && tempChart.setOption(option);
    },[seriesTempData])
  return (
    <div >
        {/*统计信息 最大 最小 平均值展示  */}
        <Spin size="large" spinning={isWaiting} style={{display:'flex',height:'100%',width:'100%'}}>
        <div style={{ padding: 5 }} className='flexCon' id='devicechart' >
            {/* 用户  区域  时间选择 间隔 查询 */}
            <div style={{ marginBottom: '10px', fontSize: '14px', fontWeight: 'bold', flexDirection: 'row',  display: 'flex' ,justifyContent:'space-between'}}>
                <div>
                    {
                        
                        <span><span>用户:</span><span style={{ fontWeight: 'normal', fontSize: '16px' }}>{Mode==='soc'?userName:localCompany}</span></span>
                    }
                    <span style={{ marginLeft: '10px' }}>区域:</span><span style={{ fontWeight: 'normal', fontSize: '16px' }}>{state.district.filter(item=>item.id===props.item.District)[0].name}</span>
                </div>
                    {/* 自定义时间间隔查询*/}
                    <div >
                    <RangePicker style={{borderRadius:10}}
                        allowEmpty={[false,true]}
                        ranges={{
                            今天: [moment().startOf('day'), moment()],
                            '本周': [moment().startOf('week'), moment().endOf('week')],
                            '本月': [moment().startOf('month'), moment().endOf('month')],
                            '前一天': [moment().subtract(1, 'days'), moment()],
                            '前三天': [moment().subtract(3, 'days'), moment()],
                            '前五天': [moment().subtract(5, 'days'), moment()],
                            '前七天': [moment().subtract(5, 'days'), moment()],
                            '一小时': [moment().subtract(1, 'hours'), moment()],
                            '三小时': [moment().subtract(3, 'hours'), moment()],
                            '五小时': [moment().subtract(5, 'hours'), moment()],
                            '六小时': [moment().subtract(6, 'hours'), moment()],
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        onChange={(dates, dateStrings) => {
                            if(dates===null){
                                setIsChangeTime(false)
                                setStartTime(props.start);
                                setEndTime(props.end);
                            }else{
                                setIsChangeTime(true)
                                setStartTime(dateStrings[0]+':00');
                                dates[1]!==null&&setEndTime(dateStrings[1]+':00');
                            }   
                        }}
                    />
                    <Select
                        showSearch
                        bordered
                        style={{ width: 80 ,marginLeft:10,marginRight:10 }}
                        placeholder="间隔"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={value=>setMinInterval(value)}
                    >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="5">5</Option>
                        <Option value="10">10</Option>
                        <Option value="20">20</Option>
                        <Option value="30">30</Option>
                        <Option value="60">60</Option>
                    </Select>
                    <Button type="primary" icon={<SearchOutlined />} style={{borderRadius:10}} disabled={isWaiting}
                    onClick={()=>{
                        // setLoading(true);
                        setIsWaiting(true);
                        setData([]);setDataF({ tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });setdataChart([]);
                        let startT = formatDateTimes(dateStart().getTime());
                        let endT = formatDateTimes(new Date().getTime());
                        if(isChangeTime){
                            props.showMore?getHistorys(selNode,startTime,endTime,showUnitName,selDevNodes):getHistory(startTime,endTime,showUnitName);
                        }else{
                            props.showMore?getHistorys(selNode,startT,endT,showUnitName,selDevNodes):getHistory(startT,endT,showUnitName);
                        }
                    }}>

                        查询
                    </Button>
                </div>
            </div>
            {/* 主机名 主机编号  测点名称 测点 导出 */}
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <div style={{display:'flex'}}>                               
                    <span style={{ marginLeft: '10px' }}>主机名:</span> <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{props.item.MName}</span>
                    <span style={{ marginLeft: '10px',marginRight:'10px'}}>主机编号:</span> <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{props.item.Sn}</span>
                    {
                        props.showMore? 
                        <div style={{display:'flex',alignItems:'center'}}>
                            <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>测点</span>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                全选
                            </Checkbox>
                        </div>:
                        <div style={{display:'flex',alignItems:'center',marginLeft:15}}>
                            <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{ props.item.Name}</span>
                            <span style={{ marginLeft: '10px' }}>测点ID:</span> 
                            <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{props.item.Node}</span>
                        </div>
                    }
                </div>
                {/* 导出 */}
                {
                    !props.showMore&&
                    <div>
                        <a id="downloadDiv" style={{ display: 'none' }}></a>
                        {/* 保存为图片 */}
                        <Button style={{fontSize:12,borderRadius:10 }} onClick={saveImage}>保存为图片</Button>
                        {/* 下载EXCEL */}
                        {
                            state.super>1&&
                            <Button style={{ marginLeft: 5,fontSize:12,borderRadius:10}} disabled={state.main.role.indexOf(304)===-1}
                            onClick={() => {
                                setIsWaiting(true);
                                var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                                let url = "/v1/"
                                url+="historydexcel?device=" + props.item.Sn + "&node=" +  props.item.Node;
                                startTime === ''?url +=  "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                                endTime===''? url+="&starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
                                url+="&starttime=" + startTime + "&endtime=" + endTime;
                                url+="&min="+minInterval;
                                if(Mode==='soc'){
                                    fetch(url, { //downloadFiles 接口请求地址
                                        method: 'get',
                                        credentials: 'include',
                                        headers: new Headers({
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                                        })
                                    })
                                    .then((response) => {
                                        setIsWaiting(false);
                                        response.blob().then(blob => {
                                            let blobUrl = window.URL.createObjectURL(blob);
                                            let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                            let filename =  props.item.Name + '.xlsx';//设置文件名称
                                            aElement.href = blobUrl;//设置a标签路径
                                            aElement.download = filename;
                                            aElement.click();
                                            window.URL.revokeObjectURL(blobUrl);
                                        });
                                    }).catch(error => {
                                        setIsWaiting(false);
                                        console.log('文件下载失败', error);
                                    });
                                }else{
                                    if(window.CefSharp!==undefined){
                                        window.CefSharp.BindObjectAsync("bound");
                                        let path = window.bound.exportEXCEL(props.item.Name+'.xls');
                                        url+="&path=" + path;
                                        http.get(url, {}).then(data=>{
                                            setIsWaiting(false);
                                            if(data.code===0&&data.msg==="ok")message.success('EXCEL导出成功'+data.data,3);
                                        })
                                    }
                                }
                                
                            }}>导出EXCEL</Button>
                        }
                        {/* 下载PDF */}
                        <Button style={{ marginLeft: 5,fontSize:12,borderRadius:10}} disabled={state.main.role.indexOf(304)===-1}
                        onClick={() => {
                            setIsWaiting(true);
                            var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                            let url = "/v1/"
                            url+="historydpdf?device=" + props.item.Sn + "&node=" +  props.item.Node;
                            startTime === ''?url +=  "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                            endTime===''? url+="&starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
                            url+="&starttime=" + startTime + "&endtime=" + endTime; 
                            url+="&min="+minInterval;
                            if(!isExportHumi){
                                url+="&humi=false"
                            };
                            if(Mode==='soc'){
                            fetch(url, { //downloadFiles 接口请求地址
                                method: 'get',
                                credentials: 'include',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                })
                            })
                            .then(response => {
                                setIsWaiting(false);
                                response.blob().then(blob => {
                                    let blobUrl =window.URL.createObjectURL(blob);
                                    let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                    let filename =  props.item.Name + '.pdf';//设置文件名称
                                    aElement.href = blobUrl;//设置a标签路径
                                    aElement.download = filename;
                                    aElement.click();
                                    window.URL.revokeObjectURL(blobUrl);
                                });
                            }).catch((error) => {
                                setIsWaiting(false);
                                console.log('文件下载失败', error);
                            });
                        }else{
                            if(window.CefSharp!==undefined){
                                window.CefSharp.BindObjectAsync("bound");
                                let path = window.bound.exportPDF(props.item.Name+'.pdf');
                                url+="&path=" + path;
                                http.get(url, {}).then(data=>{
                                    setIsWaiting(false);
                                    if(data.code===0&&data.msg==="ok")message.success('PDF导出成功'+data.data,3);
                                }).catch(error => {
                                    setIsWaiting(false);
                                    // console.log('文件下载失败', error);
                                });
                            }
                        }
                        }}>导出PDF</Button>
                        {
                            props.item.Humi===1?<Checkbox style={{marginLeft:5}} checked={isExportHumi} onChange={(e)=>{
                                e.target.checked?setIsExportHumi(true):setIsExportHumi(false);
                            }}>导出湿度</Checkbox>:''
                        }
                        {/* <Button style={{ marginLeft: 5,fontSize:12,borderRadius:10}} disabled={state.main.role.indexOf(304)===-1}
                        onClick={() => {
                            setIsWaiting(true);
                            var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                            let url = "/v1/"
                            url+="historyreportpdf?device=" + props.item.Sn + "&node=" +  props.item.Node;
                            startTime === ''?url +=  "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                            endTime===''? url+="&starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
                            url+="&starttime=" + startTime + "&endtime=" + endTime; 
                            url+="&min="+minInterval;
                            if(Mode==='soc'){
                            fetch(url, { //downloadFiles 接口请求地址
                                method: 'get',
                                credentials: 'include',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                })
                            })
                            .then(response => {
                                setIsWaiting(false);
                                response.blob().then(blob => {
                                    let blobUrl =window.URL.createObjectURL(blob);
                                    let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                    let filename =  props.item.Name + '.pdf';//设置文件名称
                                    aElement.href = blobUrl;//设置a标签路径
                                    aElement.download = filename;
                                    aElement.click();
                                    window.URL.revokeObjectURL(blobUrl);
                                });
                            }).catch((error) => {
                                setIsWaiting(false);
                                console.log('文件下载失败', error);
                            });
                        }else{
                            if(window.CefSharp!==undefined){
                                window.CefSharp.BindObjectAsync("bound");
                                let path = window.bound.exportPDF(props.item.Name+'.pdf');
                                url+="&path=" + path;
                                http.get(url, {}).then(data=>{
                                    setIsWaiting(false);
                                    if(data.code===0&&data.msg==="ok")message.success('PDF导出成功'+data.data,3);
                                }).catch(error => {
                                    setIsWaiting(false);
                                    // console.log('文件下载失败', error);
                                });
                            }
                        }
                        }}>导出报告</Button> */}
                        {
                            Mode==='local' &&
                            <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{color:'red',fontSize:10}}>只可导出不能导入</span>
                            </div>
                        }
                    </div>
                }
            </div>
            {
                props.showMore&&
                <div>
                    <Checkbox.Group options={showSelDevNodes}  value={checkedList} onChange={onCheckChange}/>
                </div>
            }
            {/* 数据展示 */}
            <div className='flexCon'>
                {
                    // 开关门  电源
                    props.item.Node===0?null: props.item.Humi === 1 ? 
                    //温湿度
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 ,marginTop:20}}>
                            <div style={{ width: 150 }}>{`${showUnitName}（${showUnit}）：`}</div>
                            <div style={{ width: 100 }}>上限值：{data.length > 0 ? data[0].temp_h : ''}</div>
                            <div style={{ width: 100 }}>下限值：{data.length > 0 ? data[0].temp_l : ''}</div>
                            <div style={{ width: 100 }}>最大值：{dataF.tmax.toFixed(2)}</div>
                            <div style={{ width: 100 }}>最小值：{dataF.tmin.toFixed(2)}</div>
                            <div style={{ width: 100 }}>平均值：{dataF.tavg.toFixed(2)}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                            <div style={{ width: 150 }}>湿度（%RH）：</div>
                            <div style={{ width: 100 }}>上限值：{data.length > 0 ? data[0].humi_h : ''}</div>
                            <div style={{ width: 100 }}>下限值：{data.length > 0 ? data[0].humi_l : ''}</div>
                            <div style={{ width: 100 }}>最大值：{dataF.hmax.toFixed(2)}</div>
                            <div style={{ width: 100 }}>最小值：{dataF.hmin.toFixed(2)}</div>
                            <div style={{ width: 100 }}>平均值：{dataF.havg.toFixed(2)}</div>
                        </div> 
                    </div>
                    : //没有湿度
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 ,marginTop:20}}>
                        <div style={{ width: 150 }}>{`${showUnitName}（${showUnit}）：`}</div>
                        <div style={{ width: 100 }}>上限值：{data.length > 0 ? data[0].temp_h : ''}</div>
                        <div style={{ width: 100 }}>下限值：{data.length > 0 ? data[0].temp_l : ''}</div>
                        <div style={{ width: 100 }}>最大值：{dataF.tmax.toFixed(2)}</div>
                        <div style={{ width: 100 }}>最小值：{dataF.tmin.toFixed(2)}</div>
                        <div style={{ width: 100 }}>平均值：{dataF.tavg.toFixed(2)}</div>
                    </div>
                }
                {/* 图表展示 */}
                {
                    props.showMore?//多测点
                    <Tabs activeKey={tabKey} style={{height:'65vh',overflow:'auto'}} defaultActiveKey="1" 
                        tabBarExtraContent={
                            <div>
                                <a id="downloadDiv" style={{ display: 'none' }}></a>
                                {/* 保存为图片 */}
                                <Button style={{ borderRadius:10 ,fontSize:12}} onClick={saveImage}>保存为图片</Button>
                                {/* 下载EXCEL */}
                                {
                                    state.super>1&&
                                    <Button style={{marginLeft:5,borderRadius:10,fontSize:12}} disabled={state.main.role.indexOf(304)===-1}
                                onClick={() => {
                                    var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                                    let url = "/v1/"
                                    url+="historydexcel?device=" +props.item.Sn + "&node=" + selNode;
                                    startTime===''?url+= "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                                    endTime===''? url+="&starttime=" + startTime+"&endtime=" + formatDateTimes(new Date().getTime()):
                                    url+="&starttime=" + startTime+"&endtime=" + endTime;
                                    url+="&min="+minInterval;
                                    if(Mode==='soc'){
                                        fetch(url, { //downloadFiles 接口请求地址
                                            method: 'get',
                                            credentials: 'include',
                                            headers: new Headers({
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                            })
                                        })
                                        .then((response) => {
                                            response.blob().then(blob => {
                                                let blobUrl = window.URL.createObjectURL(blob);
                                                let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                                let filename = props.item.Sn + '.xlsx';//设置文件名称
                                                aElement.href = blobUrl;//设置a标签路径
                                                aElement.download = filename;
                                                aElement.click();
                                                window.URL.revokeObjectURL(blobUrl);
                                            });
                                        }).catch((error) => {
                                            console.log('文件下载失败', error);
                                        });
                                    }else{
                                        if(window.CefSharp!==undefined){
                                            window.CefSharp.BindObjectAsync("bound");
                                            let path = window.bound.exportEXCEL(props.item.Sn + '.xlsx');
                                            url+="&path=" + path;
                                            http.get(url, {}).then(data=>{
                                                if(data.code===0&&data.msg==="ok")message.success('EXCEL导出成功'+data.data,3);
                                            })
                                        }
                                    }
                                }}>导出EXCEL</Button>
                                }
                                
                                {/* 下载PDF */}
                                <Button style={{marginLeft:5,borderRadius:10,fontSize:12}} disabled={state.main.role.indexOf(304)===-1}
                                onClick={() => {
                                    var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                                    let url = "/v1/";
                                    url += "historydpdf?device=" + props.item.Sn + "&node=" + selNode  ;
                                    startTime === ''?url +=  "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                                    endTime===''? url+="&starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
                                    url+="&starttime=" + startTime + "&endtime=" + endTime; 
                                    url+="&min="+minInterval;
                                    if(Mode==='soc'){
                                        fetch(url, { //downloadFiles 接口请求地址
                                            method: 'get',
                                            credentials: 'include',
                                            headers: new Headers({
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                            })
                                        })
                                        .then((response) => {
                                            response.blob().then(blob => {
                                                let blobUrl = window.URL.createObjectURL(blob);
                                                let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                                let filename = props.item.Sn + '.pdf';//设置文件名称
                                                aElement.href = blobUrl;//设置a标签路径
                                                aElement.download = filename;
                                                aElement.click();
                                                window.URL.revokeObjectURL(blobUrl);
                                            });
                                        }).catch((error) => {
                                            console.log('文件下载失败', error);
                                        });
                                    }else{
                                        if(window.CefSharp!==undefined){
                                            window.CefSharp.BindObjectAsync("bound");
                                            let path = window.bound.exportPDF(props.item.Sn + '.pdf');
                                            url+="&path=" + path;
                                            http.get(url, {}).then(data=>{
                                                if(data.code===0&&data.msg==="ok")message.success('PDF导出成功'+data.data,3);
                                            })
                                        }
                                    }
                                    
                                }}>导出PDF</Button>
                            </div>
                        } destroyInactiveTabPane={true} onChange={onChange_Tab} 
                    >
                        <Tabs.TabPane tab="报表显示" key="1">
                            <Table 
                                bordered
                                columns={[...columnsLeft, ...selNodeColums, ...columnsRight]}
                                scroll={{x:'80%'}}
                                size={'small'}  dataSource={data} rowKey={"data_time"} loading={isWaiting} pagination={{ hideOnSinglePage: true,current: tableCurrent, onChange:onChange_Table,pageSize:tablePageSize}}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="时间轴曲线图" key="2">
                            <div style={{border: '1px solid #ebedf1'}} className='flexCon'>
                                <div></div>
                                <div id='tempEcharts'></div>
                                <div style={{display:'flex',justifyContent:'space-between',paddingRight:'10%',paddingLeft:50}}>
                                    <div>
                                        <span>开始时间:</span>
                                        <span>{startTime}</span>
                                    </div>
                                    <div>
                                        <span>结束时间:</span>
                                        <span>{endTime}</span>
                                    </div>
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={showUnitName+'曲线'} key="3">
                            <div style={{ border: '1px solid #ebedf1', padding: 10 }}>
                                <Line {...configtemps} onReady={antdPointClick}/>
                            </div>
                            {
                                dataChart.length>0&&!showBound&&
                                <div style={{textAlign:'center'}}>
                                    <span style={{fontSize:10,color:'red'}}>您选择的测点包含多个温（湿）度区间或者是空库，故不展示区间辅助线</span>
                                </div>
                            }
                        </Tabs.TabPane>
                        {
                            isHumi&&
                            <Tabs.TabPane tab="湿度曲线" key="4">
                                <div style={{ border: '1px solid #ebedf1', padding: 10 }}>
                                    <div>
                                        <Line {...confighumis} onReady={antdPointClick}/>
                                    </div>
                                </div>
                                {
                                    dataChart.length>0&&!showBound&&
                                    <div style={{textAlign:'center'}}>
                                        <span style={{fontSize:10,color:'red'}}>您选择的测点包含多个温（湿）度区间或者是空库，故不展示区间辅助线</span>
                                    </div>
                                }
                            </Tabs.TabPane>
                        }
                    </Tabs>
                    ://单测点node0
                    props.item.Node===0?
                    <Tabs> 
                        <Tabs.TabPane tab="报表显示" key="1">
                            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={"data_time"} columns={columns} size='small' dataSource={data} 
                            />
                        </Tabs.TabPane>
                    </Tabs>
                    ://单测点
                    <Tabs style={{height:'65vh',overflow:'auto'}} destroyInactiveTabPane={true} onChange={onChange_Tab} activeKey={tabKey}>
                        <Tabs.TabPane tab="报表显示" key="1" >
                            <Table bordered pagination={{ hideOnSinglePage: true,current: tableCurrent, onChange:onChange_Table,pageSize:tablePageSize}} rowKey={"data_time"} columns={columns} size='small' dataSource={data}/>
                            
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="时间轴曲线图" key="2">
                            <div style={{border: '1px solid #ebedf1'}} className='flexCon'>
                                <div></div>
                                <div id='tempEcharts'></div>
                                <div style={{display:'flex',justifyContent:'space-between',paddingRight:'10%',paddingLeft:50}}>
                                    <div>
                                        <span>开始时间:</span>
                                        <span>{startTime}</span>
                                    </div>
                                    <div>
                                        <span>结束时间:</span>
                                        <span>{endTime}</span>
                                    </div>
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={showUnitName+'曲线'} key="3">
                            <div style={{ border: '1px solid #ebedf1', padding: 10 }}>
                                <Line {...configtemp}  onReady={antdPointClick}/>
                            </div>
                        </Tabs.TabPane>
                        {
                            isHumi&&
                            <Tabs.TabPane tab="湿度曲线" key="4">
                                <div style={{ border: '1px solid #ebedf1', padding: 10 }}>
                                    <div>
                                        <Line {...confighumi} onReady={antdPointClick}/>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        }
                    </Tabs>
                }
                
            </div>
        </div>
        </Spin>
    </div>
  )
}

export default DeviceHistory