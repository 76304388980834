import React, { useContext, useEffect, useState } from 'react'
import { Table, DatePicker, Select, Button, message, Tabs, Spin, Cascader, Tag, Row, Col } from 'antd'
import { StateContext} from '../../../state';
import { StateDeviceContext } from '../../../state_device';
import http from '../../../../../utils/server'
import { Line } from '@ant-design/charts';
import './style.less'
import moment from 'moment';
import { Mode } from '../../../../../App'
import html2canvas from 'html2canvas';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import * as echarts from 'echarts';

// 绘制图表
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
var todayTime=0;

const customWeekStartEndFormat = value =>
  `${moment(value).startOf('week').format('MM/DD')} ~ ${moment(value)
    .endOf('week')
    .format('MM/DD')}`;

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};

function exportCsv(obj) {
    var title = obj.title;
    var titleForKey = Object.keys(obj.data[0]);
    var data = obj.data;
    var str = [];
    str.push(obj.title.join(",") + "\n");
    for (var i = 0; i < data.length; i++) {
        var temp = [];
        for (var j = 0; j < titleForKey.length; j++) {
            temp.push(data[i][titleForKey[j]]);
        }
        str.push(temp.join(",") + "\n");
    }
    var blob = new Blob(['\uFEFF' + str.join('')], {
        type: 'text/plain;charset=utf-8',
    });
    var downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "export.csv";
    downloadLink.click();
}
var userdistrictlist;
function History() {
    const { state, dispatch } = useContext(StateContext)
    const { state_device, dispatch_device } = useContext(StateDeviceContext)
    const [loading, setLoading] = useState(false);
    const [showDevName, setShowDevName] = useState(null);//选中的主机名
    const [selDevOptions, setSelDevOptions] = useState([]);//区域主机选项
    const [devFliters, setDevFliters] = useState([]);//所有主机选项
    const [dis, setDis] = useState('');
    const [isHumi, setIsHumi] = useState(false);
    const [selNode, setSelNode] = useState([]);
    const [selNodeColums, setSelNodeColums] = useState([]);//多测点查询表格列显示内容
    const [selDevice, setSelDevice] = useState({});//已选择的测点

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [data, setData] = useState([])//报表数据
    const [dataF, setDataF] = useState({ tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 })//查询设备的最大 最小 平均值
    const [dataChart, setdataChart] = useState([])//直线图数据
    const [nodeFliters, setNodeFliters] = useState([]);
    const [selNodes, setSelNodes] = useState([]);
    const [showNodeName, setShowNodeName] = useState([]);
    const [interval,setInterval] = useState(0);
    const [userName,setUserName] = useState('');
    const [localCompany,setLocalCompany] = useState('');//本地公司名称
    const [dateTime,setDateTime] = useState('');//周期报表时间
    const [startWeek,setStartWeek] = useState('');//周报表开始时间
    const [endWeek,setEndWeek] = useState('');//周报表结束时间
    const [monthTime,setMonthTime] = useState('');//月报表时间
    const [AMTimes,setAMTimes] = useState([9]);//报表每日上午时间点
    const [PMTimes,setPMTimes] = useState([15]);//报表每日上午时间点
    const [tableType,setTableType] = useState(0);//报表类型 0普通历史数据  1日报表  2周报表 3月报表
    const [selNodeType,setSelNodeType] = useState(201);//设备主机类型  类型不同单位显示不同
    const [seriesTempData,setSeriesTempData] = useState([]);
    const [tempChart,setTempChart] = useState(undefined);
    const [dataWainF, setDataWainF] = useState({ tl: 0, th: 0,  hl: 0, hh: 0 })
    const [showBound,setShowBound] = useState(false);
    const [isEmpty,setIsEmpty] = useState(false);
    const [selDevs,setSelDevs] = useState([]);
    const [recordCount,setRecordCount] = useState('');//记录条数
    const [tabKey,setTabKey] = useState("1")
    const [tableCurrent,setTableCurrent] = useState(1)
    const [tablePageSize,setTablePageSize] = useState(10)
    const [isExportHumi,setIsExportHumi] = useState(true);
    useEffect(() => {
        let today =  new Date().setHours(0,0,0);
        todayTime = new Date(today).getTime();
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data });
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) });
            }
        })
        http.get("/v1/userdistricts", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'user_district', user_district: data.data });
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data });
                let arrNode = new Array();
                let arrDev = new Array();
                data.data.filter(x=>x.node>0).sort((a, b) => a.sn - b.sn).map(m => {
                    arrNode.push({ label: m.name+'-'+m.sn, value: m.id, sn: m.sn ,expire:m.expire});
                    arrDev.findIndex(x => x.value === m.sn) === -1 && arrDev.push({ label: m.m_name+'-'+m.sn, value: m.sn ,expire:m.expire});
                })
                setDevFliters(arrDev);
                setNodeFliters(arrNode);
                setSelNodes(arrNode);
                setSelDevOptions(arrDev.filter(x=>new Date(x.expire).getTime()>todayTime));
            }
        })
        http.get("/v1/devicestatus", {}).then(data => {
            if (data.code === 0) {
              if (data.data !== null) { 
                dispatch_device({ type: 'devicestate', devicestate: data.data });
               }
            }
          })
        window.addEventListener('resize',()=>{
            setTimeout(() => {
                tempChart&&tempChart.resize();
                tempChart&&tempChart.setOption(option);
            }, 500);
        })
    }, []);
    useEffect(()=>{
        tempChart&& option && tempChart.setOption(option);
    },[seriesTempData,showBound,selNodeType])
    //报表查询上午时间选项
    const AMOptions=[
        {label:'0点',value:0},
        {label:'1点',value:1},
        {label:'2点',value:2},
        {label:'3点',value:3},
        {label:'4点',value:4},
        {label:'5点',value:5},
        {label:'6点',value:6},
        {label:'7点',value:7},
        {label:'8点',value:8},
        {label:'9点',value:9},
        {label:'10点',value:10},
        {label:'11点',value:11},
    ];
    //报表查询下午时间选项
    const PMOptions=[
        {label:'12点',value:12},
        {label:'13点',value:13},
        {label:'14点',value:14},
        {label:'15点',value:15},
        {label:'16点',value:16},
        {label:'17点',value:17},
        {label:'18点',value:18},
        {label:'19点',value:19},
        {label:'20点',value:20},
        {label:'21点',value:21},
        {label:'22点',value:22},
        {label:'23点',value:23},
    ]
    const option = {
        tooltip: {
            trigger: 'axis',
        },
        title: {
            left: 'center',
            text:isHumi?`${userName}\n${startTime}-${endTime}曲线图 \n 温度最小值${dataF.tmin}温度最大值${dataF.tmax}湿度最小值${dataF.hmin}湿度最大值${dataF.hmax}`:`${userName}\n${startTime}-${endTime}曲线图 \n 温度最小值${dataF.tmin}温度最大值${dataF.tmax}`
          },
        legend:{
            top:80,
            type:'scroll',
        },
        xAxis: {
            type: 'time',
            axisLabel:{
                formatter:'{HH}:{mm}:{ss} \n {yyyy}-{MM}-{dd} '
            },
            min:startTime,
            max:endTime
        },
        yAxis: {
            type: 'value',

            min: function (value) {
                return value.min.toFixed(1) - 2;
            },
            max : function (value) {
                return value.max.toFixed(1) +2;
            },      
            axisLabel:{
                formatter:function (value, index) {
                    return value.toFixed(1) ;
                }
            }
        },
        grid:
        {
            left:50,
            rigeht:0,
            bottom:0,
            top:120,
            containLabel:true
        },
        // dataZoom: [
        //   {
        //     type: 'inside',
        //     start: 0,
        //     end: 20
        //   },
        //   {
        //     start: 0,
        //     end: 20
        //   }
        // ],
        series: seriesTempData,
    };   
    const configtemp = {
        data: dataChart.filter(x=>x.node!==0),
        padding: 'auto',
        xField: 'data_time',
        // height: 200,
        autoFit:true,
        yField: 'temp',
        seriesField: 'name',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    var i = v.indexOf('.');
                    return i===-1?v:v.slice(0,i+2);
                },
            },
            // tickCount:10
            max:(isEmpty||dataF.tmax> dataWainF.th)?dataF.tmax+2:dataWainF.th+2,
            min:!isEmpty&&dataWainF.tl<dataF.tmin?dataWainF.tl-2:dataF.tmin-2,
        },
        
        xAxis:{
            label:{
                offset:20
            }
        },
        slider: {
            start: 0,
            end:1,
            backgroundStyle:{
                fill: 'white',
                fillOpacity: 0,
                columnStyle: {
                    fill: 'white',
                    fillOpacity: 0,
                }
            },
            foregroundStyle:{
                fill: 'white',
                fillOpacity: 0,
            },
            trendCfg:{
                lineStyle:{stroke:'white'}
            }
        },
        annotations:showBound? [
            {
                type: 'line',
                start: ['start',dataWainF.th],
                end:['end',dataWainF.th],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.th],
                content: `${dataWainF.th+(selNodeType===205?'Pa':selNodeType===210?'MPa':'℃')}(上)`,
                offsetY: -4,
                offsetX:-14,
                style: {
                  textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['start',dataWainF.tl],
                end:['end',dataWainF.tl],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.tl],
                content: `${dataWainF.tl+(selNodeType===205?'Pa':selNodeType===210?'MPa':'℃')}(下)`,
                offsetY: 18,
                offsetX:-14,
                style: {
                  textBaseline: 'bottom',
                },
            },
        ]:[],
        // color: '#D62A0D',
        color: ['#00bc12','#D62A0D', '#9900FF', '#000000','#335541','#656485','#784512','#255454','#621532','#785423','#996655','#996699','#9999CC','#99CC99','#99FFFF'],
    };
    const confighumi = {
        data: dataChart.filter(x=>x.node!==0),
        padding: 'auto',
        xField: 'data_time',
        // height: 200,
        autoFit:true,
        yField: 'humi',
        seriesField: 'name',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    var i = v.indexOf('.');
                    return i===-1?v:v.slice(0,i+2);
                },
            },
            // tickCount:10
            max:(!isEmpty&&dataWainF.hh>dataF.hmax)?(dataWainF.hh>97?100:dataWainF.hh+2):(dataF.hmax>97?100:dataF.hmax+2),
            min:(!isEmpty&&dataWainF.hl<dataF.hmin)?(dataWainF.hl<3?0:dataWainF.hl-2):(dataF.hmin<3?0:dataF.hmin-2),
        },
        xAxis: {
            label: {
                offset:20
            },
        },
        slider: {
            start: 0,
            end:1,
            backgroundStyle:{
                fill: 'white',
                fillOpacity: 0,
                columnStyle: {
                    fill: 'white',
                    fillOpacity: 0,
                }
            },
            foregroundStyle:{
                fill: 'white',
                fillOpacity: 0,
            },
            trendCfg:{
                lineStyle:{stroke:'white'}
            }
        },
        annotations: showBound?[
            {
                type: 'line',
                start: ['start',dataWainF.hh],
                end:['end',dataWainF.hh],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.hh],
                content: dataWainF.hh+'(上)',
                offsetY: -4,
                offsetX: -18,
                style: {
                  textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['start',dataWainF.hl],
                end:['end',dataWainF.hl],
                style: {
                  stroke: '#F4664A',
                  lineDash: [2, 2],
                },
            },
            {
                type: 'text',
                position: ['min', dataWainF.hl],
                content: dataWainF.hl+'(下)',
                offsetY: 18,
                offsetX: -18,
                style: {
                  textBaseline: 'bottom',
                },
            },
        ]:[]
        ,
        // color: '#1979C9',
    };
    const columnsLeft = [
        {
            title:'记录条数:'+recordCount,
            children:[
                {
                    title: '时间',
                    dataIndex: 'data_time',
                    key: 'id',
                    align: 'center  ',
                    width: 160,
                    fixed: true,
                    sorter: (a, b) =>new Date(a.data_time).getTime()  -new Date(b.data_time).getTime() ,
                }
            ]
        },
    ]
    const columnsRight = [

        {
            title: '信号',
            dataIndex: 'csq',
            key: 'id',
            align: 'center',
            width: 80,
        },
        {
            title: '电源',
            dataIndex: 'power',
            key: 'id',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <div>{record.power === 0 ? "正常" : "电池"}</div>
            )
        },
        {
            title: '电量',
            dataIndex: 'battery',
            key: 'id',
            align: 'center',
            width: 80,
        },
    ]
    if (Mode==='soc') {
        //登录用户是普通用户
        if(state.main.type===104){
            const ud = state.district;
            userdistrictlist = new Array();
            ud.map(item => {
                var u = new Object;
                u.key = item.id;
                u.title = item.name;
                u.label = item.name;
                u.text = item.name;
                u.value =item.id;
                u.name = item.name;
                // u.id = item.id
                userdistrictlist.unshift(u);
            })
        }else if(state.systemuser.treeM){
            userdistrictlist = JSON.parse(JSON.stringify(state.systemuser.treeM));
            if (userdistrictlist && userdistrictlist.length > 0) {
                 addUserDistrict(userdistrictlist);
            }
        }
    }
    if (Mode!=='soc' && state.district) {
        userdistrictlist = new Array();
        state.district.map(item => {
            var u = new Object;
            u.key = item.id;
            u.title = item.name;
            u.label = item.name;
            u.text = item.name;
            u.value = item.id;
            u.name = item.name;
            // u.id = item.id
            userdistrictlist.push(u);
        })

    }
    function addUserDistrict(userlist) {
        for (var i = 0; i < userlist.length; i++) {
            if (!userlist[i].children) {
                let ud=[];
                userlist[i].id&& (ud = state.district.filter(x => x.user == userlist[i].id));//筛查userlist[i]创建的所有区域
                if (ud.length > 0) {
                    userlist[i].children = [];
                    ud.map(item => {
                        var u = new Object;
                        u.key = item.id;
                        u.title = item.name;
                        u.label = item.name;
                        u.text = item.name;
                        u.value = 'dis'+item.id;
                        u.name = item.name;
                        userlist[i].children.unshift(u);
                    })
                }
                else{
                    userlist[i].id&&(userlist.splice(i,1));
                    // userlist[i].id&&(userlist[i].disabled=true);
                }
            } else {
                let ud=[];
                userlist[i].id&& (ud = state.district.filter(x => x.user == userlist[i].id));//筛查userlist[i]创建的所有区域
                if (ud.length > 0) {
                    ud.map(item => {
                        var u = new Object;
                        u.key = item.id;
                        u.title = item.name;
                        u.label = item.name;
                        u.text = item.name;
                        u.value = 'dis'+item.id;
                        u.name = item.name;
                        // u.id = item.id
                        userlist[i].children.unshift(u);
                    })
                }
                addUserDistrict(userlist[i].children);
            }
        }
    }
    
    const onChange_Tab = key => {
        setTabKey(key)
        if (key==='2'){
            setTimeout(() => {
                let chartDom = document.getElementById('tempEcharts');
                if(chartDom){
                    let myChart = echarts.init(chartDom);
                    option && myChart.setOption(option);
                    myChart.resize();
                    myChart&&setTempChart(myChart);
                    myChart&&myChart.dispatchAction({
                        type: 'legendScroll',
                        scrollDataIndex: 5,
                        // legendId: string
                    })
                }    
            }, 500);
            
        }else{
            tempChart&&tempChart.dispose();
        }
    };
    const onChange_Table = (page,pageSize) =>{
        setTableCurrent(page)
        setTablePageSize(pageSize)
    }
    const antdPointClick =(plot) => {
        plot.on('element:click', args => {
         const {x,y} = args
         let points = args.data.points;
         // let data = args.data.data;
         points = points.reverse()
         let ele = points.reduce((prec,cur)=>{
             let xLen = Math.abs(prec.x-x);
             let xLen1 = Math.abs(cur.x-x);
             let yLen = Math.abs(prec.y-y);
             let yLen1 = Math.abs(cur.y-y);
             if(Math.sqrt(xLen*xLen+yLen*yLen)<Math.sqrt(xLen1*xLen1+yLen1*yLen1)){
                 return prec
             }else{
                 return cur
             }
         });
         let index = points.findIndex(val=>val.x===ele.x&&val.y===ele.y)
         setTabKey('1');
         setTableCurrent(Math.ceil(index/tablePageSize))
       });
     }
    return (
        <>
            
            <div id="devicechart">
                <div className='flexDiv'>
                    {/* {
                        Mode==='local'&&<div style={{textAlign:'center',fontSize:24}}>{localCompany}</div>
                    } */}
                    {/* 设备选择 */}
                    <div style={{ display: 'flex', alignItems: 'center', padding:'10px 40px 10px 20px' ,justifyContent:'space-between'}}>
                        {/* 区域选择 */}    
                        <div style={{display:'flex',alignItems:'center'}}>
                            <span style={{ fontSize: 14 }}>区域</span>
                            <Cascader style={{ width: 300, marginLeft: 10 }}
                                options={userdistrictlist} expandTrigger="hover" placeholder="选择区域" autoFocus onChange={val => {
                                    // setUserName(state.systemuser.list.filter(x=>x.id===val[val.length-2])[0].name);
                                    setSelNodes(nodeFliters);
                                    setSelDevOptions(devFliters.filter(x=>new Date(x.expire).getTime()>todayTime));
                                    setShowDevName(null);
                                    setShowNodeName([]);
                                    setSelDevice({});
                                    setSelNodeColums([]);
                                    if(val.length===0) return
                                    let arrNode = new Array();
                                    let arrDev = new Array();
                                    state.device.filter(x => x.district === val[val.length - 1].slice(3)*1&&x.node>0).sort((a, b) => a.sn - b.sn).map(m => {
                                        arrNode.push({ label: m.name+'-'+m.sn, value: m.id, sn: m.sn,expire:m.expire });
                                        arrDev.findIndex(x => x.value === m.sn) === -1 && arrDev.push({ label: m.m_name+'-'+m.sn, value: m.sn ,expire:m.expire});
                                    })
                                    setDis(val[val.length - 1].slice(3)*1);
                                    if (arrNode.length === 0) {
                                        message.info('该区域下暂无设备信息');
                                        setSelNodes([])
                                        setSelDevOptions([]);
                                    } else {
                                        setSelNodes(arrNode);
                                        setSelDevOptions(arrDev.filter(x=>new Date(x.expire).getTime()>todayTime));
                                    }
                                    
                                }} showSearch={(inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)} />
                        </div>
                        {/* 主机方式查询 */}
                        <div style={{display:'flex',alignItems:'center'}}>
                            <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>主机</span>
                            <Select showSearch optionFilterProp="label" allowClear 
                            disabled={showNodeName.length > 0}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={value => {
                                setShowDevName(value)
                                //初始化
                                setDataF({tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });
                                setdataChart([]);
                                setData([]);
                                let sd;
                                dis === '' ? sd = state.device.filter(x => x.sn === value) :
                                    sd = state.device.filter(x => x.sn === value && x.district === dis)
                                if (sd.length > 0) {
                                    setSelDevice(sd[0]);
                                    setUserName(sd[0].user_name);
                                    setIsHumi(sd[0].humi===0?false:true)
                                } else {
                                    setSelDevice({});
                                    return
                                }
                                setSelDevs(sd);
                                var nodes = new Array();
                                sd.map(m=>{
                                    nodes.push(m.node);
                                })
                                setSelNode(nodes);

                            }}
                            options={selDevOptions}
                            value={showDevName}
                            style={{ width: 260, marginLeft: 10 }}
                            placeholder="选择主机" />
                        </div>
                        {/* 测点方式查询 */}
                        <div style={{display:'flex',alignItems:'center'}}>
                            <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>测点</span>
                            <Select allowClear showSearch optionFilterProp="children"
                                mode="multiple"
                            // showSearch optionFilterProp="label"
                                disabled={!(showDevName === null || showDevName === undefined)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={showNodeName}
                                maxTagCount = 'responsive'
                                style={{ width: 360, marginLeft: 10 }}
                                placeholder="选择测点"
                                onChange={value => {
                                    setShowNodeName(value);
                                    //初始化
                                    setDataF({tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });
                                    setdataChart([]);
                                    setData([]);
                                    const sd = state.device.filter(x => { return value.indexOf(x.id) > -1 });
                                    if (sd.length > 0) {
                                        setSelDevice(sd[0]);
                                        setUserName(sd[0].user_name);
                                        setSelNodeType(sd[0].type);
                                    } else {
                                        setSelDevice({});
                                        return
                                    }
                                    if(sd.length>1){
                                        sd.sort((a,b)=>a.node-b.node);
                                        if(sd[0].node===0){
                                            let temp = sd.splice(0,1);
                                            sd.push(temp[0]);
                                        }   
                                    }
                                    setSelDevs(sd);
                                    var nodes = new Array();
                                    sd.map(m=>{
                                        nodes.push(m.node);
                                    })
                                    setSelNode(nodes);
                                }}
                            >
                                {
                                    selNodes.filter(x => new Date(x.expire).getTime()>todayTime&&(selDevice.sn === undefined ? x.sn > 0 : x.sn === selDevice.sn)).map(item => (
                                        <Select.Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    )) 
                                }
                            </Select>
                        </div>
                    </div>
                    {/*时间 报表类型选择   查询*/}
                    <Row align='middle'  style={{padding:'10px 40px 10px 20px'}} >
                        {/* 是否查询周期报表 */}
                        <Col xxl={2} xl={4} lg={4}>
                            <Checkbox onChange={e=>{
                                if(e.target.checked){
                                setTableType(3);
                                }else{
                                    setTableType(0);
                                }
                                
                            }}>周期报表</Checkbox>
                        </Col>
                        {/* 周期报表查询 */}
                        <Col xxl={11} xl={tableType>0?18:0} lg={tableType>0?18:0}>
                            <div style={{display:'flex'}}>
                                {/* 选择周期报表类型月报表|周报表|日报表 */}
                                <div style={{marginLeft:10}}>
                                    <Tag color={tableType===3?"cyan":'green'} style={{fontSize:14,padding:5,borderRadius:10}}
                                        onClick={()=>{
                                            if(tableType===0)return
                                            setDateTime('');
                                            setMonthTime('');
                                            setStartWeek('');
                                            setEndWeek('');
                                            tableType===3?setTableType(2):tableType===2?setTableType(1):setTableType(3);
                                        }}
                                    >
                                        {tableType===1?'日报表':tableType===2?'周报表':'月报表'}
                                    </Tag>
                                </div>
                                {/* 选择周期报表每天上午的时间点 */}
                                <div style={{marginLeft:10,display:'flex',width:150,alignItems:'center'}}>
                                    <div><span style={{fontSize:14}}>上午:</span></div>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '60%',marginLeft:10 }}
                                        placeholder="选择时间点"
                                        defaultValue={[9]}
                                        options = {AMOptions}
                                        onChange={value=>setAMTimes(value)}
                                    />
                                </div>
                                {/* 选择周期报表每天下午的时间点 */}
                                <div style={{marginLeft:10,display:'flex',width:160,alignItems:'center'}}>
                                    <div ><span>下午:</span></div>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '60%',marginLeft:10 }}
                                        placeholder="选择时间点"
                                        defaultValue={[15]}
                                        options = {PMOptions}
                                        onChange={value=>setPMTimes(value)}
                                    />
                                </div>
                                {/* 选择周期报表查询时间段 */}
                                <div>
                                    {
                                        tableType===1?
                                        <RangePicker style={{ marginLeft: 10 ,borderRadius:10}}
                                            allowEmpty={[false,true]}
                                            ranges={{
                                                今天: [moment().startOf('day'), moment()],
                                                '前一天': [moment().subtract(1, 'days'), moment()],
                                                '前三天': [moment().subtract(3, 'days'), moment()],
                                                '前五天': [moment().subtract(5, 'days'), moment()],
                                                '前七天': [moment().subtract(5, 'days'), moment()],
                                            }}
                                            format="YYYY-MM-DD"
                                            onChange={(dates, dateStrings) => {
                                                if(dates===null){
                                                    setStartWeek('');
                                                    setEndWeek('');
                                                }else{
                                                    setStartWeek(dateStrings[0]+' 00:00:00');
                                                    dates[1]!==null&&setEndWeek(dateStrings[1]+' 23:59:59');
                                                }
                                                // if (dateStrings[0] !== "") {
                                                //     setStartWeek(dateStrings[0]+' 00:00:00');
                                                //     setEndWeek(dateStrings[1]+' 23:59:59');
                                                // }
                                            }}
                                        />
                                        :
                                        <DatePicker style={{borderRadius:10}}  picker={tableType===1?'date':tableType===2?"week":'month' } 
                                        value={dateTime}  
                                        format={tableType===2&&customWeekStartEndFormat}
                                        onChange ={(value,time)=>{
                                            setDateTime(value)
                                            if(tableType===2){
                                                setStartWeek(moment(value).startOf('week').format('yyyy-MM-DD HH:mm:ss'));
                                                setEndWeek(moment(value).endOf('week').format('yyyy-MM-DD HH:mm:ss'));
                                            }else if(tableType===3){
                                                setMonthTime(time+'-01');
                                            }else{
        
                                            }
                                        }}
                                        />
                                    }
                                </div>
                            </div>
                        </Col>
                        {/* 普通报表查询 */}
                        <Col xxl={10} xl={tableType===0?18:0} lg={tableType===0?18:0}>
                            <div style={{display:'flex'}}>
                                {/* 时间选择 */}
                                <div>
                                    <span margin-left='14px' style={{ fontSize: 14,  }}>选择时间</span>
                                    <RangePicker style={{ marginLeft: 10 ,borderRadius:10}}
                                        allowEmpty={[false,true]}
                                        ranges={{
                                            今天: [moment().startOf('day'), moment()],
                                            '本周': [moment().startOf('week'), moment().endOf('week')],
                                            '本月': [moment().startOf('month'), moment().endOf('month')],
                                            '前一天': [moment().subtract(1, 'days'), moment()],
                                            '前三天': [moment().subtract(3, 'days'), moment()],
                                            '前五天': [moment().subtract(5, 'days'), moment()],
                                            '前七天': [moment().subtract(5, 'days'), moment()],
                                            '一小时': [moment().subtract(1, 'hours'), moment()],
                                            '三小时': [moment().subtract(3, 'hours'), moment()],
                                            '五小时': [moment().subtract(5, 'hours'), moment()],
                                            '六小时': [moment().subtract(6, 'hours'), moment()],
                                        }}
                                        showTime
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={(dates, dateStrings) => {
                                            if(dates===null){
                                                setStartTime('');
                                                setEndTime('');
                                            }else{
                                                setStartTime(dateStrings[0]+':00');
                                                dates[1]!==null&&setEndTime(dateStrings[1]+':00');
                                            }
                                        }}
                                    />
                                </div>
                                {/* 间隔选择 */}
                                <div>
                                    <span margin-left='14px' style={{ fontSize: 14, marginLeft:20 }}>选择间隔</span>
                                    {
                                        // Mode!=='soc'&&
                                        <Select
                                        showSearch
                                        style={{ width: 80 ,marginLeft:10}}
                                        placeholder="间隔"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={value=>setInterval(value)}
                                    >
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="5">5</Option>
                                        <Option value="10">10</Option>
                                        <Option value="20">20</Option>
                                        <Option value="30">30</Option>
                                        <Option value="60">60</Option>
                                    </Select>
                                    }
                                </div>
                                
                            </div>
                        </Col>
                        {/* 查询 */}
                        <Col xxl={1} xl={2} lg={2} style={{display:'flex',justifyContent:'space-between'}}>
                            <div ></div>
                            <Button type={"primary"}  style={{borderRadius:10}}
                                onClick={() => {
                                    //初始化
                                    setDataF({tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 });
                                    setdataChart([]);
                                    setData([]);
                                    if (selDevice.sn == undefined || selDevice.node == undefined) return message.warning('未选择设备');
                                    let url = "/v1"; 
                                    let start = '';
                                    let end ='';
                                    // 普通报表
                                    if(tableType===0){
                                        
                                        var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                                        startTime===''&&setStartTime(formatDateTimes(now_date.getTime()));
                                        endTime===''&&setEndTime(formatDateTimes(new Date().getTime()));
                                        start=startTime===''?formatDateTimes(now_date.getTime()):startTime;
                                        end=endTime===''? formatDateTimes(new Date().getTime()):endTime;
                                        url += "/history?device=" + selDevice.sn + "&node=" + selNode + "&starttime=" ;
                                        url+=start+"&endtime=" + end;
                                        // startTime===''?url+= formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                                        // endTime===''? url+=startTime+"&endtime=" + formatDateTimes(new Date().getTime()):
                                        // url+=startTime+"&endtime=" + endTime;
                                    }else{
                                        // 周期报表
                                        if( tableType===3&&monthTime==='')return message.warning('未选择月份');
                                        if( tableType===2&&startWeek==='')return message.warning('未选择星期');
                                        if( tableType===1&&startWeek==='')return message.warning('未选择时间');
                                        let time ='';
                                        AMTimes.map(item=>item<10?time+=('0'+item+':00:00,'):time+=(item+':00:00,'));
                                        PMTimes.map(item=>time+=(item+':00:00,'));
                                        time=time.slice(0,-1);
                                        
                                        tableType===3?url+="/historymon?device=":url+="/historyweek?device=";
                                        url+=selDevice.sn + "&node=" + selNode + "&time=" + time;
                                        tableType===3?url+=('&mon='+monthTime):endWeek!==''?url+='&starttime='+startWeek+'&endtime='+endWeek:
                                        url+='&starttime='+startWeek+'&endtime='+formatDateTimes(new Date().getTime());
                                    }
                                    url+="&min="+interval;
                                    setLoading(true);
                                    setIsHumi(false);
                                    http.get(url,{}).then((data) => {
                                        setLoading(false);
                                        if (data.code === 0) {
                                            if (data.data===null||data.data.length === 0) return message.warning('暂无数据');
                                            let isHumi = false;
                                            let sss= data.data.find(x=>x.humi_switch!==0&&x.humi_enable!==0);//查找是否有湿度数据
                                            if(sss){
                                                setIsHumi(true);
                                                isHumi= true;
                                            }
                                            var ar = data.data.filter(item => item.temp !== 102.3 && item.temp !== 102.2 && item.humi !== 102.3 && item.humi !== 102.2 && item.node!==0&&item.temp_switch!==0);
                                            if (ar.length === 0) return message.warning('暂无有效数据');
                                            ar.sort((a,b)=>a.node-b.node); 
                                            let dev_val=[];   
                                            let isShowBound = true;                          
                                            if(selNode.length>0){
                                                var tavg = 0;
                                                var havg = 0;
                                                var hmax = 0;
                                                var hmin = 100;
                                                let tmax = Math.max.apply(Math, ar.map(o=>o.temp));
                                                let tmin = Math.min.apply(Math, ar.map(o=>o.temp));
                                                let th =0;
                                                let tl =0;
                                                let hh = 0;
                                                let hl = 0;
                                                let deviceInfos = state_device.devicestate.filter(x=>x.Sn===selDevice.sn && selNode.includes(x.Node));
                                                let deviceInfo = deviceInfos.filter(x=>x.Sensor.Hide!==2&&x.Sensor.Hide!==3 );
                                                let deviceInfoHide = deviceInfos.filter(x=>x.Sensor.Hide===2||x.Sensor.Hide===3 );
                                                if(deviceInfo.length>0){ 
                                                    th =deviceInfo[0].Sensor.TempAlarmHigh;
                                                    tl =deviceInfo[0].Sensor.TempAlarmLow;
                                                    hh = deviceInfo[0].Sensor.HumiAlarmHigh;
                                                    hl = deviceInfo[0].Sensor.HumiAlarmLow;
                                                }
                                                else{
                                                    isShowBound = false
                                                    if (deviceInfoHide.length>0){
                                                        setIsEmpty(true)
                                                    }
                                                }
                                                ar.map(item => {
                                                    if(isShowBound){
                                                        if (deviceInfoHide.length>0){
                                                            let isHas = deviceInfoHide.findIndex(x=>x.Node===item.node)
                                                            if(isHas===-1){
                                                                
                                                            }
                                                        }
                                                        if(item.temp_l!==tl||item.temp_h!==th||item.humi_h!==hh||item.humi_l!==hl){
                                                            isShowBound = false
                                                            th =0;
                                                            tl =0;
                                                            hh = 0;
                                                            hl = 0;
                                                        }
                                                    }
                                                    if(dev_val.length===0){
                                                        dev_val.push({node:item.node,temp_h:item.temp,temp_l:item.temp,humi_l:item.humi,humi_h:item.humi,temp_s:item.temp,humi_s:item.humi,count:1})
                                                    }else{
                                                        let b= dev_val.findIndex(x=>x.node===item.node);
                                                        if(b===-1){
                                                            dev_val.push({node:item.node,temp_h:item.temp,temp_l:item.temp,humi_l:item.humi,humi_h:item.humi,temp_s:item.temp,humi_s:item.humi,count:1})
                                                        }else{
                                                            dev_val[b].temp_s +=item.temp; 
                                                            dev_val[b].humi_s +=item.humi; 
                                                            dev_val[b].count +=1; 
                                                            if(item.temp>dev_val[b].temp_h){
                                                                dev_val[b].temp_h = item.temp
                                                            }
                                                            if(item.temp<dev_val[b].temp_l){
                                                                dev_val[b].temp_l = item.temp
                                                            }
                                                            if(item.humi>dev_val[b].humi_h){
                                                                dev_val[b].humi_h = item.humi
                                                            }
                                                            if(item.humi<dev_val[b].humi_l){
                                                                dev_val[b].humi_l = item.humi
                                                            }
                                                        }
                                                    }
                                                    tavg = tavg + item.temp;
                                                    if (item.humi > hmax) {
                                                        hmax = item.humi;
                                                    }
                                                    if (item.humi < hmin) {
                                                        hmin = item.humi;
                                                    }
                                                    havg = havg + item.humi;
                                                })
                                                dev_val.map(item=>{
                                                    item.temp_s = item.temp_s/item.count
                                                    item.humi_s = item.humi_s/item.count
                                                })
                                                // setValStatistical(dev_val);
                                                tavg = tavg / ar.length;
                                                havg = havg / ar.length;
                                                setDataF({ tmax, tmin, tavg, hmin, hmax, havg });
                                                setDataWainF({th,tl,hh,hl});
                                            }
                                            
                                            setdataChart(ar);
                                            start =  ar[0];
                                            end = ar[ar.length-1];
                                            let baseData=[];
                                            data.data.map(m=>{
                                                let index = baseData.findIndex(x=>x.data_time===m.data_time);
                                                if(index!==-1){
                                                    baseData[index]['temp' + m.node] = m.temp
                                                    baseData[index]['temp_h' + m.node] = m.temp_h
                                                    baseData[index]['temp_l' + m.node] = m.temp_l
                                                    baseData[index]['temp_alarm' + m.node] = m.temp_alarm
                                                    baseData[index]['temp_switch' + m.node] = m.temp_switch
                                                    baseData[index]['humi' + m.node] = m.humi
                                                    baseData[index]['humi_h' + m.node] = m.humi_h
                                                    baseData[index]['humi_l' + m.node] = m.humi_l
                                                    baseData[index]['humi_alarm' + m.node] = m.humi_alarm
                                                    baseData[index]['humi_switch' + m.node] = m.humi_switch
                                                    baseData[index]['humi_enable' + m.node] = m.humi_enable
                                                    baseData[index]['districtname' + m.node] = m.districtname
                                                    baseData[index]['door' + m.node] = m.door
                                                    baseData[index]['power' + m.node] = m.power
                                                }else{
                                                    m['temp' + m.node] = m.temp
                                                    m['temp_h' + m.node] = m.temp_h
                                                    m['temp_l' + m.node] = m.temp_l
                                                    m['temp_alarm' + m.node] = m.temp_alarm
                                                    m['temp_switch' + m.node] = m.temp_switch
                                                    m['humi' + m.node] = m.humi
                                                    m['humi_h' + m.node] = m.humi_h
                                                    m['humi_l' + m.node] = m.humi_l
                                                    m['humi_alarm' + m.node] = m.humi_alarm
                                                    m['humi_switch' + m.node] = m.humi_switch
                                                    m['humi_enable' + m.node] = m.humi_enable
                                                    m['districtname' + m.node] = m.districtname
                                                    m['door' + m.node] = m.door
                                                    m['power' + m.node] = m.power
                                                    baseData.push(m)
                                                }
                                            })
                                            // const baseData = data.data.filter(x => { return x.node == checkData.node })
                                            // for (var i = 0; i < baseData.length; i++) {
                                            //     const dataLine = data.data.filter(x => { return x.data_time == baseData[i].data_time })
                                            //     dataLine.map(({ temp, temp_h, temp_l, temp_alarm, humi, humi_h, humi_l, humi_alarm, node, districtname, temp_switch, humi_switch, humi_enable,door,power }) => {
                                            //         baseData[i]['temp' + node] = temp
                                            //         baseData[i]['temp_h' + node] = temp_h
                                            //         baseData[i]['temp_l' + node] = temp_l
                                            //         baseData[i]['temp_alarm' + node] = temp_alarm
                                            //         baseData[i]['temp_switch' + node] = temp_switch
                                            //         baseData[i]['humi' + node] = humi
                                            //         baseData[i]['humi_h' + node] = humi_h
                                            //         baseData[i]['humi_l' + node] = humi_l
                                            //         baseData[i]['humi_alarm' + node] = humi_alarm
                                            //         baseData[i]['humi_switch' + node] = humi_switch
                                            //         baseData[i]['humi_enable' + node] = humi_enable
                                            //         baseData[i]['districtname' + node] = districtname
                                            //         baseData[i]['door' + node] = door
                                            //         baseData[i]['power' + node] = power                                                   
                                            //     })
                                                
                                            // }
                                            let seriesData=[]
                                            selNode.map(item=>{
                                                let tempdata =[];
                                                let humidata =[];
                                                let name ='';
                                                ar.filter(x=>x.node ==item).map(val=>{
                                                    tempdata.push([val.data_time,val.temp]);
                                                    humidata.push([val.data_time,val.humi]);
                                                    if(name==='' ){
                                                        name =val.name
                                                    }
                                                })
                                                start.data_time!==startTime&&tempdata.unshift(null);
                                                end.data_time!==endTime&&tempdata.push(null);
                                                seriesData.push({
                                                    'name':name+'温度曲线',
                                                    type: 'line',
                                                    smooth: true,
                                                    symbol: 'none',
                                                    data: tempdata,
                                                })
                                                isHumi&&seriesData.push({
                                                    'name':name+'湿度曲线',
                                                    type: 'line',
                                                    smooth: true,
                                                    symbol: 'none',
                                                    data: humidata,
                                                })
                                            })
                                            setData(baseData.reverse());
                                            setRecordCount(baseData.length+'');
                                            setSeriesTempData(seriesData);
                                            let sd = selDevs.concat()
                                            var nodeCol = new Array();
                                            if(sd.length>1){
                                                sd.sort((a,b)=>a.node-b.node);
                                                if(sd[0].node===0){
                                                    let temp = sd.splice(0,1);
                                                    sd.push(temp[0]);
                                                }
                                            }
                                            sd.map(({ node, name, district_name, m_name,id,door_alarm ,type,humi}) => {
                                                let devNode= dev_val.filter(x=>x.node===node);
                                                // nodes.push(node);
                                                var t = 'temp' + node;
                                                // 断电 开关门
                                                if(node===0){
                                                    door_alarm===1&&
                                                    nodeCol.push({
                                                        title: `${name}-(区域:${district_name}-主机:${m_name}-测点ID:${node})`,
                                                        align: 'center',
                                                        children: [
                                                            {
                                                                title: '开关门',
                                                                dataIndex: 'door' + node,
                                                                width: 300,
                                                                align: 'center',
                                                                render: (text, record) => (
                                                                    <div>
                                                                        <div>{record['door' + node]  === 0 ? "关门" : '开门'}</div>
                                                                    </div>
                                                                )
                                                            },
                                                            
                                                        ]
                                                    })
                                                }else{//温湿度
                                                    humi!==0?
                                                    // 温湿度
                                                    nodeCol.push({
                                                        title: `${name}-(区域:${district_name}-主机:${m_name}-测点ID:${node})`,
                                                        align: 'center',
                                                        children: [
                                                            {
                                                                title: `温度 (最大:${devNode.length>0&&devNode[0].temp_h.toFixed(1)} 
                                                                    最小:${devNode.length>0&&devNode[0].temp_l.toFixed(1)} 
                                                                    平均:${devNode.length>0&&devNode[0].temp_s.toFixed(2)})`,
                                                                align: 'center',
                                                                children:[
                                                                    {
                                                                        title: '上下限(℃)',
                                                                        // dataIndex: 'temp_h' + node,
                                                                        align: 'center',
                                                                        width:100,
                                                                        render: (text, record) => (
                                                                            <div>
                                                                                {record['temp_switch' + node] === 0 ? null :record['temp_h' + node]===undefined?'': <div>{`${record['temp_h' + node]}~${record['temp_l' + node]}`}</div>}
                                                                            </div>
                                                                        )
                                                                    },
                                                                    {
                                                                        title: '温度(℃)',
                                                                        dataIndex: 'temp' + node,
                                                                        align: 'center',
                                                                        width:100,
                                                                        render: (text, record) => (
                                                                            <div>
                                                                                <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{ record['temp_switch' + node] === 0 ? "--" : record['temp' + node]?record['temp' + node] > 102.1 ? " NL" :record['temp' + node].toFixed(1):''}</div>
                                                                            </div>
                                                                        )
                                                                    },
                                                                    {
                                                                        title: '状态',
                                                                        dataIndex: 'temp_alarm' + node,
                                                                        align: 'center',
                                                                        width: 60,
                                                                        render: (text, record) => (
                                                                            <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{record['temp_switch' + node] === 0 ? "--" :record['temp_alarm' + node] === 0 ? '正常' :record['temp_alarm' + node] === 1 ? '报警':null}</div>
                                                                        )
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                title: `湿度 (最大:${devNode.length>0&&devNode[0].humi_h} 
                                                                    最小:${devNode.length>0&&devNode[0].humi_l} 
                                                                    平均:${devNode.length>0&&devNode[0].humi_s.toFixed(2)})`,
                                                                align: 'center',
                                                                children:[
                                                                    {
                                                                        title: '上下限(%RH)',
                                                                        // dataIndex: 'humi_h' + node,
                                                                        align: 'center',
                                                                        width: 100,
                                                                        render: (text, record) => (
                                                                            <div>
                                                                                {record['humi_switch' + node] === 0 || record['humi_enable' + node]===0 ? null : record['humi_h' + node]?<div>{`${record['humi_h' + node]}~${record['humi_l' + node]}`}</div>:''}
                                                                            </div>
                                                                        )
                                                                    },
                                                                    {
                                                                        title: '湿度(%RH)',
                                                                        dataIndex: 'humi' + node,
                                                                        align: 'center',
                                                                        width: 100,
                                                                        render: (text, record) => (
                                                                            <div>
                                                                                <div style={{color:record['humi_alarm' + node] === 1 ?'red':''}}>{ record['humi_switch' + node] === 0 ? "--" : record['humi_enable' + node]===0 ?"--":record['humi' + node] > 102.1 ? " NL" : record['humi' + node]?record['humi' + node].toFixed(1):''}</div>
                                                                            </div>
                                                                        )
                                                                    },
                                                                    {
                                                                        title: '状态',
                                                                        dataIndex: 'humi_alarm' + node,
                                                                        width: 60,
                                                                        align: 'center',
                                                                        render: (text, record) => (
                                                                            <div style={{color:record['humi_alarm' + node] === 1 ?'red':''}}>{record['humi_switch' + node] === 0 ? "--" : record['humi_enable' + node]===0 ?"--" :record['humi_alarm' + node] === 0 ? '正常' : record['humi_alarm' + node] === 1 ? '报警':''}</div>
                                                                        )
                                                                    },
                                                                ]
                                                            },
                                                            
                                                        ]
                                                    }):
                                                    // 单温设备
                                                    nodeCol.push({
                                                        title: `${name}-(区域:${district_name}-主机:${m_name}-测点ID:${node})`,
                                                        align: 'center',
                                                        children:[
                                                        {
                                                                title:`${(type===205||type===210)?'压强':'温度'}  (最大:${devNode.length>0&&devNode[0].temp_h.toFixed(1)} 
                                                                最小:${devNode.length>0&&devNode[0].temp_l.toFixed(1)} 
                                                                平均:${devNode.length>0&&devNode[0].temp_s.toFixed(2)})`,
                                                                align:'center',
                                                                children: [
                                                                    {
                                                                        title: type===205?'上下限(Pa)':type===210?'上下限(MPa)':'上下限(℃)',
                                                                        // dataIndex: 'temp_h' + node,
                                                                        align: 'center',
                                                                        width:100,
                                                                        render: (text, record) => (
                                                                            <div>
                                                                                {record['temp_switch' + node] === 0 ? null :record['temp_h' + node]===undefined?'': <div>{`${record['temp_h' + node]}~${record['temp_l' + node]}`}</div>}
                                                                            </div>
                                                                        )
                                                                    },
                                                                    {
                                                                        title: type===205?'压强(Pa)':type===210?'压强(MPa)':'温度(℃)',
                                                                        dataIndex: 'temp' + node,
                                                                        key: 'temp' + id,
                                                                        align: 'center',
                                                                        width:80,
                                                                        render: (text, record) => (
                                                                            <div>
                                                                                <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{ record['temp_switch' + node] === 0 ? "--" :record['temp' + node]? record['temp' + node] > 102.1 ? " NL" :record['temp' + node].toFixed(1):''}</div>
                                                                            </div>
                                                                        )
                                                                    },
                                                                    {
                                                                        title: '状态',
                                                                        dataIndex: 'temp_alarm' + node,
                                                                        key: 'temp_alarm' + id,
                                                                        align: 'center',
                                                                        width: 60,
                                                                        render: (text, record) => (
                                                                            <div style={{color:record['temp_alarm' + node] === 1 ?'red':''}}>{record['temp_switch' + node] === 0 ? "--" :record['temp_alarm' + node] === 0 ? '正常' :record['temp_alarm' + node] === 1 ? '报警':''}</div>
                                                                        )
                                                                    },
                                                                ]
                                                        }
                                                        ]
                                                    })
                                                }
                                                
                                            })
                                            setSelNodeColums(nodeCol)
                                            setShowBound(isShowBound)
                                        }
                                    })
                                    console.log('设备类型'+selNodeType);
                                }}>查询</Button>
                        </Col>
                    </Row>
                    <div style={{ padding: '10px 40px 10px 20px', border: '1px solid #ebedf1' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: 150 }}>{selNodeType===205?'压强(Pa)':selNodeType===210?'压强(MPa)':'温度(℃)'}: 上限值:{data.length > 0 ? data[0].temp_h : ''}</div>
                            <div style={{ width: 100 }}>下限值:{data.length > 0 ? data[0].temp_l : ''}</div>
                            <div style={{ width: 100 }}>最大值:{dataF.tmax}</div>
                            <div style={{ width: 100 }}>最小值:{dataF.tmin}</div>
                            <div style={{ width: 100 }}>平均值:{dataF.tavg.toFixed(2)}</div>
                            {
                                isHumi&&<div style={{ width: 150 }}>湿度(%RH):   上限值:{data.length > 0 ? data[0].humi_h : ''}</div>
                            }
                            {
                                isHumi&&<div style={{ width: 100 }}>下限值:{data.length > 0 ? data[0].humi_l : ''}</div>
                            }
                            {
                                isHumi&&<div style={{ width: 100 }}>最大值:{dataF.hmax}</div>
                            }
                            {
                                isHumi&&<div style={{ width: 100 }}>最小值:{dataF.hmin}</div>
                            }
                            {
                                isHumi&&<div style={{ width: 100 }}>平均值:{dataF.havg.toFixed(2)}</div>
                            }
                        </div>
                    </div>

                    {/* 数据展示区 */}
                    <div style={{ padding: 5, marginLeft: 15, marginRight: 15,flex:1}}className='flexDiv'>
                        {
                            // selNode.length<2&&
                            
                        }
                        <div className='flexDiv' style={{flex:1}}>
                        <Spin spinning={loading} size="large" tip="加载中..." />
                        <Tabs defaultActiveKey="1" activeKey={tabKey}  className={`flexDiv`} style={{height:'75vh',visibility:loading?'hidden':'visible'}} 
                            tabBarExtraContent={
                                data&&data.length>0&&
                                <div style={{paddingRight:20}}>
                                    {/* 保存为图片 */}
                                    <Button style={{ borderRadius:10 ,fontSize:12}} onClick={() => {
                                        setLoading(true);
                                        var canvas2 = document.createElement("canvas");
                                        let _canvas = document.getElementById('devicechart');
                                        var w = parseInt(window.getComputedStyle(_canvas).width);
                                        var h = parseInt(window.getComputedStyle(_canvas).height);
                                        canvas2.width = w ;
                                        canvas2.height = h ;
                                        // canvas2.style.marginLeft =  "10px";
                                        // canvas2.style.marginTop = "10px";
                                        var context = canvas2.getContext("2d");
                                        context.scale(1, 1);
                                        html2canvas(document.getElementById('devicechart'), { canvas: canvas2 }).then((canvas) => {
                                            // canvas.style.width = (w - 40) + "px";
                                            // canvas.style.height = (h - 40) + "px";
                                            canvas.id = 'cutimg'
                                            var url = canvas.toDataURL('image/png');
                                            if(window.CefSharp!==undefined){
                                                window.CefSharp.BindObjectAsync("bound");
                                                window.bound.exportImg(url);
                                     
                                            }else{
                                                var downloadLink = document.createElement("a");
                                                downloadLink.href = url;
                                                downloadLink.download = "sd.png";
                                                downloadLink.click();
                                                
                                            }
                                            setLoading(false);
                                        });
                                    }}>保存为图片</Button>
                                    {/* 下载EXCEL */}
                                    {
                                        state.super>1&&
                                        <Button style={{marginLeft:5,borderRadius:10,fontSize:12}} disabled={state.main.role.indexOf(304)===-1}
                                    onClick={() => {
                                        var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                                        let url = "/v1/"
                                        let time ='';
                                        AMTimes.map(item=>item<10?time+=('0'+item+':00:00,'):time+=(item+':00:00,'));
                                        PMTimes.map(item=>time+=(item+':00:00,'));
                                        time=time.slice(0,-1);
                                        //普通报表下载
                                        if(tableType===0)
                                        {
                                            url+="historydexcel?device=" +selDevice.sn + "&node=" + selNode;
                                            startTime===''?url+= "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                                            endTime===''? url+="&starttime=" + startTime+"&endtime=" + formatDateTimes(new Date().getTime()):
                                            url+="&starttime=" + startTime+"&endtime=" + endTime;
                                            url+="&min="+interval;
                                        }
                                        //月报表下载
                                        if(tableType===3){
                                            url+="historymondexcel?device=" +selDevice.sn + "&node=" + selNode+ "&time=" + time+ '&mon='+monthTime;
                                        }
                                        //周,日报表下载
                                        if(tableType===2||tableType===1){
                                            url+="historyweekdexcel?device="+selDevice.sn + "&node=" + selNode+ "&time=" + time +'&starttime='+startWeek+'&endtime=';
                                            url+=endWeek===''?formatDateTimes(new Date().getTime()):endWeek;
                                        }
                                        if(Mode==='soc'){
                                            fetch(url, { //downloadFiles 接口请求地址
                                                method: 'get',
                                                credentials: 'include',
                                                headers: new Headers({
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                                })
                                            })
                                            .then((response) => {
                                                response.blob().then(blob => {
                                                    let blobUrl = window.URL.createObjectURL(blob);
                                                    let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                                    let filename = selDevice.name + '.xlsx';//设置文件名称
                                                    aElement.href = blobUrl;//设置a标签路径
                                                    aElement.download = filename;
                                                    aElement.click();
                                                    window.URL.revokeObjectURL(blobUrl);
                                                });
                                            }).catch((error) => {
                                                console.log('文件下载失败', error);
                                            });
                                        }else{
                                            if(window.CefSharp!==undefined){
                                                window.CefSharp.BindObjectAsync("bound");
                                                let path = window.bound.exportEXCEL(selDevice.m_name + '.pdf');
                                                url+="&path=" + path;
                                                http.get(url, {}).then(data=>{
                                                    if(data.code===0&&data.msg==="ok")message.success('EXCEL导出成功'+data.data,3);
                                                })
                                            }
                                        }
                                    }}>导出EXCEL</Button>
                                    }
                                    
                                    {/* 下载PDF */}
                                    <Button style={{marginLeft:5,borderRadius:10,fontSize:12}} disabled={state.main.role.indexOf(304)===-1}
                                    onClick={() => {
                                        var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                                        let url = "/v1/"
                                        let time ='';
                                        AMTimes.map(item=>item<10?time+=('0'+item+':00:00,'):time+=(item+':00:00,'));
                                        PMTimes.map(item=>time+=(item+':00:00,'));
                                        time=time.slice(0,-1);
                                        //普通报表下载
                                        if(tableType===0)
                                        {
                                            url += "historydpdf?device=" + selDevice.sn + "&node=" + selNode + "&starttime=" ;
                                            startTime===''?url+= formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                                            endTime===''? url+=startTime+"&endtime=" + formatDateTimes(new Date().getTime()):
                                            url+=startTime+"&endtime=" + endTime;
                                            url+="&min="+interval;
                                        }
                                        //月报表下载
                                        if(tableType===3){
                                            url+="historymondpdf?device=" +selDevice.sn + "&node=" + selNode+ "&time=" + time+ '&mon='+monthTime;
                                        }
                                        //周报表下载
                                        if(tableType===2||tableType===1){
                                            url+="historyweekdpdf?device="+selDevice.sn + "&node=" + selNode+ "&time=" + time +'&starttime='+startWeek+'&endtime=';
                                            url+=endWeek===''?formatDateTimes(new Date().getTime()):endWeek;
                                        }
                                        if(!isExportHumi){
                                            url+="&humi=false"
                                        };
                                        if(Mode==='soc'){
                                            fetch(url, { //downloadFiles 接口请求地址
                                                method: 'get',
                                                credentials: 'include',
                                                headers: new Headers({
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                                })
                                            })
                                            .then((response) => {
                                                response.blob().then(blob => {
                                                    let blobUrl = window.URL.createObjectURL(blob);
                                                    let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                                    let filename = selDevice.m_name + '.pdf';//设置文件名称
                                                    aElement.href = blobUrl;//设置a标签路径
                                                    aElement.download = filename;
                                                    aElement.click();
                                                    window.URL.revokeObjectURL(blobUrl);
                                                });
                                            }).catch((error) => {
                                                console.log('文件下载失败', error);
                                            });
                                        }else{

                                            if(window.CefSharp!==undefined){
                                                window.CefSharp.BindObjectAsync("bound");
                                                let path = window.bound.exportPDF(selDevice.m_name + '.pdf');
                                                url+="&path=" + path;
                                                http.get(url, {}).then(data=>{
                                                    if(data.code===0&&data.msg==="ok")message.success('PDF导出成功'+data.data,3);
                                                })
                                            }
                                        }
                                        
                                    }}>导出PDF</Button>
                                    {
                                        isHumi&&<Checkbox style={{marginLeft:5}} checked={isExportHumi} onChange={(e)=>{
                                            e.target.checked?setIsExportHumi(true):setIsExportHumi(false);
                                        }}>导出湿度</Checkbox>
                                    }
                                    {
                                        Mode==='local' &&
                                        <div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style={{color:'red',fontSize:10}}>只可导出不能导入</span>
                                        </div>
                                    }
                                    
                                    
                                </div>
                            } 
                            onChange={onChange_Tab}>
                                <TabPane tab="报表显示" key="1" style={{height:'100%',backgroundColor:'#fff'}} className={Mode==='soc'?`hisshow tableshow1`:`flexDiv local`}>
                                    <div>
                                        <Table
                                            // style={{height:'100%',overflow:'auto'}}
                                            bordered
                                            columns={[...columnsLeft, ...selNodeColums, ...columnsRight]}
                                            scroll={{ x: isHumi?300 + selNode.length * 600: 300 + selNode.length * 300}}
                                            size={'small'} dataSource={data} rowKey={'data_time'} loading={loading}
                                            pagination={{ hideOnSinglePage: true,current: tableCurrent, onChange:onChange_Table,pageSize:tablePageSize}}
                                            />
                                    </div>
                                </TabPane>
                                <TabPane tab="时间轴曲线图" key="2" style={{backgroundColor:'#fff'}} className={Mode==='soc'?`hisshow tableshow1`:`flexDiv local`}>
                                    <div style={{display:'flex',flexDirection:'column',border: '1px solid #ebedf1',height:'100%'}}>
                                        <div></div>
                                        <div  id='tempEcharts'>
                                            {/* <Line {...configtemp} /> */}
                                        </div>
                                        <div style={{display:'flex',justifyContent:'space-between',paddingRight:'10%',paddingLeft:50}}>
                                            <div>
                                                <span>开始时间:</span>
                                                <span>{startTime}</span>
                                            </div>
                                            <div>
                                                <span>结束时间:</span>
                                                <span>{endTime}</span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="温度曲线" key="3" style={{backgroundColor:'#fff'}} className={Mode==='soc'?`hisshow tableshow1`:`flexDiv local`}>
                                    <div style={{display:'flex',flexDirection:'column',border: '1px solid #ebedf1',height:'100%'}}>
                                        <div  id='tempEcharts' >
                                            <Line {...configtemp} onReady={antdPointClick}/>
                                        </div>
                                        
                                    </div>
                                    {
                                        dataChart.length>0&&  !showBound&&
                                        <div style={{textAlign:'center'}}>
                                            <span style={{fontSize:10,color:'red'}}>您选择的测点包含多个温（湿）度区间或者是空库，故不展示区间辅助线</span>
                                        </div>
                                    }
                                </TabPane>
                                {
                                    isHumi&&
                                    <TabPane tab="湿度曲线" key="4"  style={{backgroundColor:'#fff'}} className={Mode==='soc'?`hisshow tableshow1`:`flexDiv local`}>
                                        <div style={{display:'flex',flexDirection:'column', border: '1px solid #ebedf1', padding: 10 ,height:'100%'}} >
                                            <div id='tempEcharts' >
                                                <Line {...confighumi} onReady={antdPointClick} />
                                            </div>
                                            
                                        </div>
                                        {
                                            dataChart.length>0&& !showBound&&
                                            <div style={{textAlign:'center'}}>
                                                <span style={{fontSize:10,color:'red'}}>您选择的测点包含多个温（湿）度区间或者是空库，故不展示区间辅助线</span>
                                            </div>
                                        }
                                    </TabPane>
                                }
                        </Tabs>
                            
                        </div>
                    </div>
                    {/* 下载区 */}
                    <div style={{ marginTop: 10, textAlign: 'right' }}>
                        <a id="downloadDiv" style={{ display: 'none' }}></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default History