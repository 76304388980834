import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal, Checkbox, Row, Col, Upload, Form, TreeSelect ,DatePicker, Space,message, Menu, Dropdown} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import md5 from 'js-md5';
import AreaManage from '../Area/index'
import { LoadingOutlined, PlusOutlined, ExclamationCircleOutlined , SearchOutlined, DownOutlined} from '@ant-design/icons';
import "./index.less"
import moment from 'moment';
import Draggable from 'react-draggable';

const AreaContext = createContext();

function findParentuser(nop, list, child) {
    if (list) {
        const nops = list.filter(x => { return x.parent === nop })
        if (nops.length === 0) {
            return
        } else {
            for (var j = 0; j < nops.length; j++) {
                var u = new Object
                u.key = nops[j].id
                u.name = nops[j].name
                u.type = nops[j].type
                u.parent = nops[j].parent
                u.id = nops[j].id
                u.title = nops[j].name
                u.titles = nops[j].title
                u.text = nops[j].name
                u.label = nops[j].name
                u.value = nops[j].id
                u.account = nops[j].account
                u.smscount = nops[j].smscount
                u.smssendcount = nops[j].smssendcount
                u.wechatcount = nops[j].wechatcount
                u.company = nops[j].company
                u.children = new Array()
                child.push(u);
                findParentuser(nops[j].id, list, u.children)
            }
        }
    }
}
function clearchildrenisZero(list) {
    for (var i = 0; i < list.length; i++) {
        if (list[i].children) {
            if (list[i].children.length === 0) {
                list[i].children = null
            } else {
                clearchildrenisZero(list[i].children)
            }
        }
    }
}
function UserNew() {
    const { actUser, dispatchUser, form, onfinishNew } = useContext(AreaContext)
    const { state } = useContext(StateContext)
    const [selectUserType, setSelectUserType] = useState(101)
    const [selectUserRole, setSelectUserRole] = useState([])
    const [isCompany, setIsCompany] = useState(false);
    const formRef = React.createRef();
    const [defExpend,setDefExpend]=useState([]);

    useEffect(() => {
        form.setFieldsValue(actUser);
        var userLogin = state.systemuser.list[0];
        var list = [];
        state.systemuser.list.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
        setIsCompany((state.main.type<103)?true:false);
        if(state.main.type>102){
            form.setFieldsValue({title:state.main.title,parent:state.main.id});
            setSelectUserType(state.main.type)//上级用户的类型
            setSelectUserRole(state.main.role.split(",").map(Number))//上级用户拥有的权限
        }
        form.setFieldsValue({
            company:(state.main.type<103)?1:0,
            title:(state.main.type<103)&&''
        });
        
    }, [])


    return (
        <div >
            <Form
                form={form}
                ref={formRef}
                onFinish={onfinishNew}>
                    <Form.Item label="上级用户"
                        name="parent"
                        rules={[
                            {
                                required: true,
                                message: '选择上级用户'
                            },
                        ]}>
                        <TreeSelect
                            // treeDefaultExpandAll
                            showSearch
                            treeNodeFilterProp='name'
                            treeData={state.systemuser.treeM}
                            treeDefaultExpandedKeys={defExpend}
                            style={{ width: '100%', flex: 1 }}
                            placeholder="上级用户"
                            onChange={value => {

                                const u = state.systemuser.list.filter(x => { return x.id === value })
                                if (u.length > 0 ) {
                                    setSelectUserType(u[0].type)//上级用户的类型
                                    setSelectUserRole(u[0].role.split(",").map(Number))//上级用户拥有的权限
                                    // formRef.current.setFieldsValue({
                                    //     type: '',
                                    //     company:0
                                    // })
                                    // setIsCompany(true);
                                }
                            }}
                        />
                    </Form.Item>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Form.Item  label="用户名称"
                        style={{width:350}}
                        name="name"
                        rules={[{ required: true, message: '输入用户名称' }]}>
                        <Input />
                    </Form.Item>
                    <div id='form_company'>
                        <Form.Item label="创建新组织" 
                            name="company"
                        >
                            <Checkbox checked={isCompany} onChange={e=>{
                                var tempP = form.getFieldValue('parent');
                                if(tempP==='') return message.warning('请先选择上级用户');
                                setIsCompany(!isCompany);
                                form.setFieldsValue({company:e.target.checked?1:0});
                                if(!e.target.checked){
                                    let companyUser={};
                                    function findParent(nop,list){
                                        let temp = list.filter(x=>x.id===nop)
                                        if(temp.length===0){
                                            companyUser = {};
                                            return
                                        }else{
                                            if(temp[0].id===0) {
                                                companyUser = {};
                                                return
                                            }else if(temp[0].company===1){
                                                companyUser = temp[0]
                                                return
                                            }else{
                                                findParent(temp[0].parent,list);
                                            }
                                        }
                                        
                                    }
                                    findParent(tempP*1,state.systemuser.list);
                                    if(JSON.stringify(companyUser)==='{}')return message.warning('请先创建公司账号!!!!!!!!');
                                    formRef.current.setFieldsValue({
                                        title:companyUser.title
                                    })
                                }
                                else{
                                    formRef.current.setFieldsValue({
                                        title:''
                                    })
                                }
                            }}></Checkbox>
                        </Form.Item>
                    </div>
                </div>
                {
                    <Form.Item label="所属组织"
                        name="title"
                        rules={[{ required: true, message: '输入公司名称' }]}>
                        <Input  disabled={!isCompany} />
                    </Form.Item>
                }
                <Form.Item label="用户账号"
                    name="account"
                    rules={[{ required: true, message: '输入用户账号' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="登录密码"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录密码',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item label="重复登录密码"
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请再输入一次登录密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不相同'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item label="用户类型"
                    name="type"
                    dependencies={['parent']}
                    rules={[{ required: true, message: '用户类型' }]}>
                    <Select
                        // defaultValue={104}
                        style={{ width: '100%', flex: 1 }}
                        placeholder="用户类型"
                        onSelect={value=>{
                            let elem = document.getElementById('form_company')
                            value===104?elem.style.setProperty('display','none'):elem.style.setProperty('display','block')
                            var userRole=[];
                            let tempRole = state.main.type<102?selectUserRole:selectUserRole.filter(x=>x!==303);//不是admin没有历史数据管理分配权限
                            tempRole.map(item=>{
                                if(value===102&&item<315&&item!==303){
                                    userRole.push(item);
                                }if(value===103&&item<310&&item!==303){
                                    userRole.push(item);
                                }if(value===104&&item===301){
                                    userRole.push(item); 
                                }
                            })
                            formRef.current.setFieldsValue({
                                roles:userRole
                            })
                            
                            // value===102?setUserType(102):value===103?setUserType(103):setUserType(104);
                        }}
                    >
                        {
                            (selectUserType <= 102 && isCompany) ? 
                            <Select.Option key={102} value={102}>
                                高级用户
                                {/* 管理员 */}
                            </Select.Option> : null
                        }
                        {
                            selectUserType <= 103 ? 
                            <Select.Option key={103} value={103}>
                                管理用户
                                {/* 技术员 */}
                            </Select.Option> : null
                        }
                        {
                            !isCompany?
                            <Select.Option key={104} value={104}>
                                普通用户
                                {/* 操作员 */}
                            </Select.Option>:null
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="权限"
                    name="roles"
                    rules={[{ required: true, message: '选择权限' }]}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%', flex: 1 }}
                        placeholder="权限类型"
                        maxTagCount={'responsive'}
                    >
                        {
                            state.permissiontype.filter(x =>  selectUserRole.indexOf(x.code) > -1).map(item => (
                                <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>

        </div>
    )
}
function UserFix() {
    const { actUser, dispatchUser, form, onfinish, userParentRoles } = useContext(AreaContext)
    const { state } = useContext(StateContext)
    const [selectUserType, setSelectUserType] = useState(101)
    const [selectUserRole, setSelectUserRole] = useState([])
    const formRef = React.createRef();
    useEffect(() => {
        // console.log(state.main.type ,state.main.type > 103);
        form.setFieldsValue(actUser)
        setSelectUserRole(actUser.role.split(','));
        setSelectUserType(actUser.type);
    }, [])

    return (
        <Form
            form={form}
            ref={formRef}
            onFinish={onfinish}>
            <Form.Item label="用户名称"
                name="name"
                rules={[{ required: true, message: '输入用户名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item  label="用户账号"
                name="account"
                rules={[{ required: true, message: '输入用户账号' }]}>
                <Input disabled />
            </Form.Item>
            <Form.Item label="用户类型"
                name="type"
                dependencies={['parent']}
                rules={[{ required: true, message: '用户类型' }]}>
                <Select
                    // defaultValue={104}
                    style={{ width: '100%', flex: 1 }}
                    placeholder="用户类型"
                >
                    {
                        (state.main.type < 103) ? 
                        <Select.Option key={102} value={102}>
                            高级用户
                            {/* 管理员 */}
                        </Select.Option> : null
                    }
                    {
                        (state.main.type <= 103 &&selectUserType>=103)? 
                        <Select.Option key={103} value={103}>
                            管理用户
                            {/* 技术员 */}
                        </Select.Option> : null
                    }
                    <Select.Option key={104} value={104} disabled={true}>
                        普通用户
                        {/* 操作员 */}
                    </Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="权限"
                name="roles"
                rules={[{ required: true, message: '选择权限' }]}>
                <Select
                    mode="multiple"
                    style={{ width: '100%', flex: 1 }}
                    placeholder="权限类型"
                    // maxTagCount={'responsive'}
                    disabled={state.main.id===actUser.id}
                >

                    {
                        state.permissiontype.map(item => (
                            <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <Form.Item label="所属组织"
                name="title"
                rules={[{ required: true, message: '输入公司名称' }]}>
                <Input  disabled={actUser.company===0}/>
            </Form.Item>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                {
                    state.main.type <102&&
                    <Form.Item label="用户禁用"
                        name="forbiden"
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                }
                {
                    state.main.type <102&&
                    <Form.Item label="新组织"
                        name="company"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={actUser.type>103}  
                        onChange ={e=>{
                            if(e.target.checked){
                                form.setFieldsValue({company:1});
                            }else{
                                var tempP = form.getFieldValue('parent');
                                let companyUser={};
                                function findParent(nop,list){
                                    let temp = list.filter(x=>x.id===nop)
                                    if(temp.length===0){
                                        companyUser = {};
                                        return
                                    }else{
                                        if(temp[0].id===0) {
                                            companyUser = {};
                                            return
                                        }else if(temp[0].company===1){
                                            companyUser = temp[0]
                                            return
                                        }else{
                                            findParent(temp[0].parent,list);
                                        }
                                    }
                                    
                                }
                                findParent(tempP*1,state.systemuser.list);
                                formRef.current.setFieldsValue({
                                    title:companyUser.title,
                                    company:0
                                })
                            }
                           
                        }}
                        />
                    </Form.Item>
                }
            </div>
            
        </Form>
    )
}
function UserManage() {
    const [form] = Form.useForm()
    const { state, dispatch } = useContext(StateContext)
    const [actUser, dispatchUser] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.user
        } else if (action.type === 'clear') {
            return {
                role: '',
                roles: [],
                password: "",
                phone: '',
                name: '',
                account: '',
                npw1: '',
                npw2: '',
                parent:'',
                type:''
            }
        }
        return state
    },[]);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)

    const [showLogo, setShowLogo] = useState(false)
    const [logoloading, setLogoLoading] = useState(false)
    const [showArea, setShowArea] = useState(false)
    const [showSelectArea, setShowSelectArea] = useState(false)
    const [userSelectArea, setUserSelectArea] = useState({ user: 0, district: [] })
    const [selArea, setSelArea] = useState({});//选中的区域信息
    const [reSetPassWord, setReSetPassWord] = useState('')
    const [isreSetPassWord, setIsReSetPassWord] = useState(false);
    const [userParentRoles, setUserParentRoles] = useState('');
    const [showNotify, setShowNotify] = useState(false);
    const [showSms, setShowSms] = useState(false);//短信分配弹窗
    const [showPhone, setShowPhone] = useState(false);//短信分配弹窗
    const [showWx, setShowWx] = useState(false);//微信报警数量修改弹窗
    const [userRecord, setUserRecord] = useState({});//当前用户
    const [smsNum, setSmsNum] = useState(0);//分配短信数量
    const [phoneNum, setPhoneNum] = useState(0);//分配语音数量
    const [wxCount, setWxCount] = useState(0);//每日微信报警数量
    const [todo_time, setTodo_time] = useState(moment().add(1,'years').format('yyyy-MM-DD'));
    const [modalBounds,setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0 });//对话框移动坐标
    const [modalMoveDisabled, setModalMoveDisabled] = useState(true);//对话框拖动功能关闭
    const draggleRef = React.createRef();//对话框移动
    const [userdistricts,setUserdistricts]=useState([]);//普通用户下分配的区域
    const [defExpend,setDefExpend]=useState([]);//默认展开treeselect
    const [serchUser, setSerchUser]=useState([]);
    const [custom,setCustom] = useState('');
    const [showCustom,setShowCustom] = useState(false)
    useEffect(() => {
        http.get("/v1/permissiontype", {}).then(data => {
            if (data.code === 0) {
                let temp=state.main.type<102?data.data:data.data.filter(x=>x.id!==11);//admin有所有权限  其他用户没有历史数据管理的分配权限
                dispatch({ type: 'permissiontype', permissiontype: temp })
            }
        })
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data })
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                let temp = data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh'));
                dispatch({ type: 'systemuser', systemuser: temp })
                var list = [];
                temp.filter(x=>x.parent===state.main.id).map(m=>list.push(m.id+""));
                setDefExpend(list);
                
            }
        })
        http.get("/v1/userdistricts", {}).then(data => {
            if (data.code === 0) {
                // dispatch({ type: 'systemuser', systemuser: data.data })
                setUserdistricts(data.data);
            }
        })
    }, [])

    function handleChange(info) {
        if (info.file.status === 'uploading') {
            setLogoLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            dispatchUser({ type: 'update', user: { ...actUser, logo: info.file.response.data.path } })
            setLogoLoading(false)
        }
    };
    // 对话框移动方法
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) return;
        setModalBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    const onfinish = (values) => {

        http.put("/v1/users/" + actUser.id, { ...actUser, ...values, role: values.roles.join(','),forbiden: values.forbiden? 1 : 0, }).then((data) => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
                }
            })
        })
        setShowFixModel(false)
        dispatchUser({ type: 'clear', project_id: state.main.project_id })
    }
    const onfinishNew = (values) => {
        if(values.company===0){
            let companyUser={};
            function findParent(nop,list){
                let temp = list.filter(x=>x.id===nop)
                if(temp.length===0){
                    companyUser = {};
                    return
                }else{
                    if(temp[0].id===0) {
                        companyUser = {};
                        return
                    }else if(temp[0].company===1){
                        companyUser = temp[0]
                        return
                    }else{
                        findParent(temp[0].parent,list);
                    }
                }
                
            }
            findParent(values.parent,state.systemuser.list);
            if(JSON.stringify(companyUser)==='{}')return message.warning('请先创建公司账号!!!!!!!!');
            values.belong = companyUser.id;
            // values.title = companyUser.title;
        }else{
            values.belong = 0;
        }

        http.post("/v1/users", { ...values,password:md5(values.password), role: values.roles.join(',') }).then((data) => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
                }
            })
        })
        // setShowNewModel(false)
    }
    const columns = [

        {
            title: '用户名称',
            dataIndex: 'name',
            width:300,
            align: 'left',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='用户名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['name']
                    ? (record['name'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '账号',
            dataIndex: 'account',
            width:180,
            key: 'account',
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='账号'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['account']
                    ? (record['account'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '用户类型',
            dataIndex: 'type',
            width:80,
            align: 'center',
            render: (text, record) => {
                let typeTemp = record.type === 101 ? '系统管理员' : record.type === 102 ? '高级用户' : record.type === 103 ? '管理用户' :'普通用户'
                // let typeTemp = record.type === 101 ? '系统管理员' : record.type === 102 ? '管理员' : record.type === 103 ? '技术员' :'操作员'
                return <div>{typeTemp}</div>
            },
        },
        {
            title: '公司名称',
            dataIndex: 'title',
            width:300,
            align: 'center',
            render: (text, record) => {
                if(record.company===0){
                    let companyUser={};
                    function findParent(nop,list){
                        let temp = list.filter(x=>x.id===nop)
                        if(temp.length===0){
                            companyUser = {};
                            return
                        }else{
                            if(temp[0].id===0) {
                                companyUser = {};
                                return
                            }else if(temp[0].company===1){
                                companyUser = temp[0]
                                return
                            }else{
                                findParent(temp[0].parent,list);
                            }
                        }
                        
                    }
                    findParent(record.parent,state.systemuser.list);
                    return(
                        <div>{companyUser.title}</div>
                    )
                }else{
                    return(
                        <div>{record.titles}</div>
                    )
                }},
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='用户名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['titles']
                    ? (record['titles'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '区域管理',
            dataIndex: 'area',
            width:100,
            align: 'center',
            render: (text, record) => {
                // record.type === 104 &&http.get("/v1/userdistricts/" + record.id, {}).then(data => {
                //     if (data.code === 0) {
                //         setUserDisNub(data.data.length);
                //     }
                // })
                return (
                record.type < 104 ?
                    <Button size='small' type='primary' style={{borderRadius:10}} onClick={() => { setShowArea(true); setSelArea(record) }}>区域管理({state.district.filter(x => { return x.user === record.id }).length})</Button> :
                    record.type === 104 ?
                        <Button size='small' style={{borderRadius:10}} onClick={() => {
                            setShowSelectArea(true);
                            setSelArea(record);
                            var ds = new Array();
                            userdistricts.filter(x=>x.user===record.id).map(item=>ds.push(item.district));
                            setUserSelectArea({ user: record.id, district: ds });
                            // http.get("/v1/userdistricts/" + record.id, {}).then(data => {
                            //     if (data.code === 0) {
                            //         var ds = new Array()
                            //         data.data.map(item => {
                            //             ds.push(item.district)
                            //         })
                            //         setUserSelectArea({ user: record.id, district: ds })
                            //     }
                            // })
                        }}>分配区域({userdistricts.filter(x=>x.user===record.id).length})</Button> : null
            )}
        },
        {
            title: '短信',
            dataIndex: 'smscount',
            width:240,
            align: 'center',
            render: (text, record) => (
                <div>
                    <span>剩余:{record.smscount-record.smssendcount}/总量:{record.smscount}</span>
                    {
                        record.account!==state.main.account&&
                        <Button size='small' type='primary' style={{borderRadius:10}} onClick={() => {
                            setShowSms(true);
                            setUserRecord(record);
                        }}>短信分配</Button> 
                    }
                </div>
                
            
            )
        },
        {
            title: '当日微信',
            dataIndex: 'wechatcount',
            width:180,
            align: 'center',
            render: (text, record) => {
                return (
                    <div style={{display:'flex',justifyContent:"center"}}>
                        <span>数量:{record.wechatcount}</span>
                        {
                            state.main.type<102&&
                            <div>
                                <Button size='small' type='primary' style={{borderRadius:10,marginLeft:5}} onClick={() => {
                                    setUserRecord(record);
                                    setShowWx(true);
                                }}>修改</Button> 
                                <Button size='small' type='primary' style={{borderRadius:10,marginLeft:5}} onClick={() => {
                                    http.post("/v1/userswechatcountclear/"+record.id, {}).then((data) => {
                                       console.log(data);
                                    })
                                }}>重置</Button> 
                            </div>
                            
                        }
                    </div>
                    
                
                )
            }
            
        },
        {
            title: '语音',
            dataIndex: 'phonecount',
            width:220,
            align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span>剩余:{record.phonecount-record.phonesendcount}/总量:{record.phonecount}</span>
                        {
                            record.account!==state.main.account&&
                            <Button size='small' type='primary' style={{borderRadius:10}} onClick={() => {
                                setShowPhone(true);
                                setUserRecord(record);
                            }}>语音分配</Button> 
                        }
                    </div>
                    
                
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width:60,
            align: 'center',
            width: '80px',
            render: (text, record) => (
                <div>
                    <Dropdown
                        overlay={<Menu>
                            {/* 修改 */}
                            <Menu.Item key="0">
                                <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {
                                    setShowFixModel(true)
                                    const user = state.systemuser.list.filter(x => { return x.id === record.id })
                                    if (user.length > 0) {
                                        const parent = state.systemuser.list.filter(x => { return x.id === user[0].parent })
                                        if (parent.length > 0) {
                                            setUserParentRoles(parent[0].role)
                                        }
                                        dispatchUser({ type: 'update', user: { ...user[0], roles: user[0].role.split(',').map(Number) } })
                                    }
                                }}>
                                    修改
                                </Button>
                            </Menu.Item>
                            {/* 删除 */}
                            {
                                record.account!==state.main.account&&
                                <Menu.Item key="1">
                                <Button size='small' type='primary' danger style={{ marginLeft: '10px',borderRadius:10 }} onClick={() => {
                                    Modal.confirm({
                                        centered: true,
                                        title: `确定要删除用户<${record.name}>吗？`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: `账号${record.account},删除之后将不可恢复！！`,
                                        okText: '删除',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            http.delete("/v1/users/" + record.id, { data: record }).then((data) => {
                                                if(data.code===0){
                                                    http.get("/v1/users", {}).then(data => {
                                                        if (data.code === 0) {
                                                            dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) });
                                                        }
                                                    })
                                                }else{
                                                    message.error(data.msg)
                                                }
                                            })
                                        },
                                        onCancel() {
                                        },
                                    });
                                }}>
                                    删除
                                </Button>
                            </Menu.Item>
                            }
                            {/* 重置密码 */}
                            <Menu.Item key="2">
                                <Button size='small' style={{ marginLeft: '10px' ,borderRadius:10}} type="primary" onClick={() => {
                                    setReSetPassWord(record.account);
                                    setIsReSetPassWord(true);
                                }}>
                                    重置密码
                                </Button>
                            </Menu.Item>
                            {/* LOGO */}
                            {
                                (record.type === 102 || record.type === 103) &&
                                <Menu.Item key="3">
                                    <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {
                                        setShowLogo(true)
                                        const user = state.systemuser.list.filter(x => { return x.id === record.id })
                                        if (user.length > 0) {
                                            dispatchUser({ type: 'update', user: user[0] })
                                        }
                                    }}>
                                        LOGO
                                    </Button>
                                </Menu.Item>
                            }
                            
                            {/* 添加提醒 */}
                            {
                                (record.type === 102 || record.type === 103) &&
                                <Menu.Item key="4">
                                    <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {
                                        setShowNotify(true)
                                        const user = state.systemuser.list.filter(x => { return x.id === record.id })
                                        if (user.length > 0) {
                                            dispatchUser({ type: 'update', user: user[0] })
                                        }
                                    }}>
                                        添加提醒
                                    </Button>
                                </Menu.Item>
                            }
                            {
                                state.main.type<102 &&<Menu.Item key="5">
                                <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {
                                    setShowCustom(true)
                                    const user = state.systemuser.list.filter(x => { return x.id === record.id })
                                    if (user.length > 0) {
                                        dispatchUser({ type: 'update', user: user[0] })
                                    }
                                }}>
                                    添加备注
                                </Button>
                            </Menu.Item>
                            }
                        </Menu>}>
                        <a >操作<DownOutlined /></a>
                    </Dropdown>
                </div>
            ),
        },
        {
            title: '备注',
            dataIndex: 'custom',
            align: 'center',
            className:state.main.type>101?'tableHiddle':'tableShow'
            // colSpan:(state.main.type>101?0:1),
            
            // render:()=>{
            //     if(state.main.type<102){
            //         return(<div>{userType}</div>)
            //     }
            // },
        }
    ]

    return (
        <div style={{ padding: 15,height:'100%',overflow:'auto' }}>
            <div style={{ float: 'left', marginBottom: 10 }}>
                <TreeSelect
                    showSearch
                    style={{ width: 400 }}
                    dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                    placeholder="选择用户"
                    allowClear
                    // multiple
                    optionFilterProp="label"
                    treeDefaultExpandedKeys={defExpend}
                    onChange={value=> {
                        console.log('systemusertree',state.systemuser.tree);
                        let tempArrs=[];
                        let temp=state.systemuser.list.filter(x=>x.id===value*1);
                        if(temp.length>0){
                            var u = new Object
                            u.key = temp[0].id
                            u.title = temp[0].name
                            u.titles = temp[0].title
                            u.text = temp[0].name
                            u.label = temp[0].name
                            u.value = temp[0].id
                            u.account = temp[0].account
                            u.name = temp[0].name
                            u.type = temp[0].type
                            u.parent = temp[0].parent
                            u.id = temp[0].id
                            u.smscount = temp[0].smscount
                            u.smssendcount = temp[0].smssendcount
                            u.wechatcount = temp[0].wechatcount
                            u.phonesendcount = temp[0].phonesendcount
                            u.phonecount = temp[0].phonecount
                            u.company = temp[0].company
                            u.children = new Array()
                            findParentuser(value,state.systemuser.list,u.children);
                            tempArrs.push(u)
                        }
                        clearchildrenisZero(tempArrs);
                        setSerchUser(tempArrs);
                    }}
                    treeData={state.systemuser.tree}
                    filterOption={(input, option) =>
                        // console.log(option)
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                        || option.account.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
            </div>
            <div style={{ float: 'right', marginBottom: 10 }}>
                <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 10 }}
                    onClick={() => {
                        setShowNewModel(true)
                        dispatchUser({ type: 'clear' })
                    }}>新建用户</Button>
            </div>
            <Table className='userTable' 
            expandable ={{defaultExpandedRowKeys:defExpend,rowExpandable :true}}
             bordered pagination={{ hideOnSinglePage: true ,defaultPageSize:15}} rowKey={record => record.id+''} columns={columns} size='small' 
            dataSource={serchUser.length>0?serchUser:state.systemuser.tree.length > 0 ?state.systemuser.tree:null} 
                    scroll={{y:650}}
            />
            {/* 区域管理 */}
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                        textAlign:'center',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    区域管理
                    </div>
                }
                centered 
                modalRender={modal => (
                    <Draggable
                        disabled={modalMoveDisabled}
                        bounds={modalBounds}
                        onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                destroyOnClose
                visible={showArea}
                onOk={() => setShowArea(false)}
                onCancel={() => setShowArea(false)}
                width={800}
            >
                <AreaManage userInfo={selArea} />
            </Modal>
            <Modal maskClosable={false} title="设置LOGO"
                centered
                // footer={null}
                destroyOnClose
                visible={showLogo}
                onOk={() => {
                    setShowLogo(false)
                    http.post("/v1/userslogo", { account: actUser.account, logo: actUser.logo }).then((data) => {
                        http.get("/v1/users", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
                            }
                        })
                    })
                }}
                onCancel={() => setShowLogo(false)}
                width={320}
            >
                <div className={"logoup"}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        headers={{ 'Authorization': `Bearer ${localStorage.getItem('token')}` }}
                        action="/v1/upimage"
                        // beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {
                            actUser.logo !== "" ? <img src={actUser.logo} alt="avatar" style={{ width: '100%' }} /> :
                                <div>
                                    {logoloading ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div style={{ marginTop: 8 }}>上传LOGO图片</div>
                                </div>
                        }
                    </Upload>
                </div>
            </Modal>
            {/* 区域分配 */}
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                        textAlign:'center',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    区域分配
                    </div>
                }
                centered
                // footer={null}
                modalRender={modal => (
                    <Draggable
                        disabled={modalMoveDisabled}
                        bounds={modalBounds}
                        onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                visible={showSelectArea}
                onOk={() => {
                    http.post("/v1/userdistricts", userSelectArea).then((data) => {
                        if(data.code===0){
                            http.get("/v1/userdistricts", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'systemuser', systemuser: data.data })
                                    setUserdistricts(data.data);
                                }
                            })
                        }
                    })
                    setShowSelectArea(false)
                }}
                onCancel={() => setShowSelectArea(false)}
                width={800}
            >
                <Checkbox.Group style={{ width: '100%' }} value={userSelectArea.district} onChange={checkedValues => { setUserSelectArea({ ...userSelectArea, district: checkedValues }) }}>
                    <Row>
                        {
                            state.district.filter(x => { return x.user === selArea.parent }).map(item => (
                                <Col span={6} key={item.id}>
                                    <Checkbox checked={userSelectArea.district.indexOf(item.id) > -1} key={item.id} value={item.id}>{item.name}({state.device.filter(x => { return x.district === item.id }).length})</Checkbox>
                                </Col>
                            ))
                        }
                    </Row>
                </Checkbox.Group>
            </Modal>
            {/* 新建用户*/}
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    新建用户
                    </div>
                }
                visible={showNewModel}
                modalRender={modal => (
                    <Draggable
                      disabled={modalMoveDisabled}
                      bounds={modalBounds}
                      onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchUser({ type: 'clear'})
                }}>
                <AreaContext.Provider value={{ actUser, dispatchUser, form, onfinishNew }}>
                    <UserNew />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={true} title="修改用户"
                visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}>
                <AreaContext.Provider value={{ actUser, dispatchUser, form, onfinish, userParentRoles }}>
                    <UserFix />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={true} title="修改密码"
                destroyOnClose
                visible={reSetPassWord !== ''}
                closable={false}
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit();
                    setIsReSetPassWord(false);
                    
                }}
                onCancel={() => {
                    setReSetPassWord('')
                    setIsReSetPassWord(false);
                }}>
                <Form
                    name="basic"
                    form={form}
                    onFinish={values => {
                        http.post("/v1/userspassword", { who: state.main.account, password: md5(values.password), account: reSetPassWord, newpassword: md5(values.newpassword) }).then(data => {
                            if (data.code === 0) {

                            } else {
                                Modal.warning({
                                    title: data.code,
                                    content: data.msg,
                                });
                            }
                        })
                    }}
                >
                    <Form.Item
                        label="管理密码"
                        name="password"
                        rules={[{ required: true, message: '输入当前账号密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newpassword"
                        rules={[{ required: true, message: '输入被重置的新密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="重复新密码"
                        name="newnewpassword"
                        dependencies={['newpassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '再次输入被重置的新密码',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newpassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('两次输入的密码不相同'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal maskClosable={true} title="增加提醒"
                visible={showNotify}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/userstodo", { account: actUser.account, todo: '到期年检',todo_time:todo_time}).then(res=>res.code!==0&&Modal.warning({
                        title: res.code,
                        content: res.msg,
                    }));
                    setShowNotify(false);
                }}
                onCancel={() => {
                    setShowNotify(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <Input addonBefore="提醒内容"  allowClear defaultValue='到期年检' disabled={true} />
                    <div style={{flexFlow:'row',display:'flex',marginTop:'10px',alignItems:'center'}}>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>提醒日期</span>
                        <DatePicker onChange={(date,datestring)=>setTodo_time(datestring)}  defaultValue={moment().add(1,'years')}/>
                    </div>
                </div>    
            </Modal>
            <Modal maskClosable={true} title="短信分配"
                visible={showSms}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/userssmscount", { user:userRecord.parent ,account: userRecord.account,smscount:smsNum*1}).then(res=>{
                        if(res.code===0)http.get("/v1/users", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
                            }
                        })
                    });
                    setShowSms(false);
                }}
                onCancel={() => {
                    setShowSms(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <div style={{flexFlow:'row',display:'flex',marginTop:'10px',alignItems:'center'}}>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>当前剩余数量:</span>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>{userRecord.smscount-userRecord.smssendcount}</span>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>可分配数量</span>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>
                            {
                                (()=>{
                                    let temp =  state.systemuser.list.filter(x=>x.id===userRecord.parent);
                                    return temp.length>0&&(temp[0].smscount-temp[0].smssendcount)
                                })()
                            }
                        </span>
                    </div>
                    <Input addonBefore="分配数量"  allowClear  value={smsNum} onChange={e=>{
                        // let temp =  state.systemuser.list.filter(x=>x.id===userRecord.parent);
                        // var num=temp[0].smscount-temp[0].smssendcount;
                        // if(num>200){
                        //     setSmsNum(e.target.value>200?200:e.target.value)
                        // }else{
                        //     setSmsNum(e.target.value>num?num:e.target.value)
                        // }
                        setSmsNum(e.target.value)
                    }} />
                </div>
            </Modal>
            <Modal maskClosable={true} title="语音分配"
                visible={showPhone}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/usersphonecount", { user:userRecord.parent ,account: userRecord.account,phonecount:phoneNum*1}).then(res=>{
                        if(res.code===0)http.get("/v1/users", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
                            }
                        })
                    });
                    setShowPhone(false);
                }}
                onCancel={() => {
                    setShowPhone(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <div style={{flexFlow:'row',display:'flex',marginTop:'10px',alignItems:'center'}}>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>当前剩余数量:</span>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>{userRecord.phonecount-userRecord.phonesendcount}</span>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>可分配数量</span>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>
                            {
                                (()=>{
                                    let temp =  state.systemuser.list.filter(x=>x.id===userRecord.parent);
                                    return temp.length>0&&(temp[0].phonecount-temp[0].phonesendcount)
                                })()
                            }
                        </span>
                    </div>
                    <Input addonBefore="分配数量"  allowClear  value={phoneNum} onChange={e=>{
                        setPhoneNum(e.target.value)
                    }} />
                </div>
            </Modal>
            <Modal maskClosable={true} title="微信报警数量调整"
                visible={showWx}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/userswechatcount/"+userRecord.id, { wechatcount:wxCount*1}).then(res=>{
                        if(res.code===0){
                            http.get("/v1/users", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
                                }
                            })
                        }
                    });
                    setShowWx(false);
                }}
                onCancel={() => {
                    setShowWx(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <div style={{flexFlow:'row',display:'flex',marginTop:'10px',alignItems:'center'}}>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>当前数量:{userRecord.wechatcount}</span>
                    </div>
                    <Input addonBefore="每日微信报警发送数量"  allowClear  value={wxCount} onChange={e=>{
                        setWxCount(e.target.value)
                    }} />
                </div>
            </Modal>
            <Modal maskClosable={true} title="添加备注"
                visible={showCustom}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/userscustom", { account: actUser.account, custom: custom}).then(res=>{
                        if(res.code!==0){
                            Modal.warning({
                                title: res.code,
                                content: res.msg,
                            })
                        }else{
                            http.get("/v1/users", {}).then(data => {
                                if (data.code === 0) {
                                    let temp = data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh'));
                                    dispatch({ type: 'systemuser', systemuser: temp })
                                    var list = [];
                                    temp.filter(x=>x.parent===state.main.id).map(m=>list.push(m.id+""));
                                    setDefExpend(list);
                                }
                            })
                        }
                        
                    });
                    
                    setShowCustom(false);
                }}
                onCancel={() => {
                    setShowCustom(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <Input addonBefore="备注"  allowClear defaultValue={actUser.custom} onChange={e=>{
                        console.log(e.target.value)
                        setCustom(e.target.value)
                    }} />
                </div>    
            </Modal>
        </div>
    )
}

export default UserManage